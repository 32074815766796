<template>
    <!-- rts footer style three hear starat -->
    <div class="frx-footer-area footer-bg-3 footer-three" id="contact">
        <div class="container pd-60">
            <div class="row">
                <div class="col-md-12 col-lg-3">
                    <div class="footer-four-left footer-single-wized ">

                        <router-link :to="{ name: 'HomePage'}" class="logo">
                            <img :src="companyLogo" alt="logo-area" class="define-width">
                        </router-link>
                        <p class="disc mb-4">
                            {{FooterLogoDescription}}
                            
                        </p>
                        <SocialMediaIconComponent></SocialMediaIconComponent>

                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="footer-four-left footer-single-wized paddingLeft">
                        <h5 class="title">
                            Usefull Links
                        </h5>
                        <div class="links ">
                            <ul>
                                <li v-for="link in footerNavLinks">
                                    <!--<router-link :to="{ name: link.routeUrl}">{{//link.displayText}}</router-link>-->
                                    <router-link class="nav-link"  :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                
                <!-- footer end area start -->
                <div class="col-md-12 col-lg-3">
                    <div class="footer-two-single-gallery-area three">
                        <h5 class="title">
                            Contact Us
                        </h5>
                        <div class="body contact-area">
                            <!-- single address -->
                            <div class="single-address">
                                <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <div class="discription">
                                    <p>Our Address</p>
                                    <span>{{OurAddress}}</span>
                                </div>
                            </div>
                            <!-- single address end -->
                            <!-- single address -->
                            <div class="single-address">
                                <div class="icon">
                                    <i class="fas fa-at"></i>
                                </div>
                                <div class="discription">
                                    <p>Our Email</p>
                                    <span>{{OurEmail}}</span>
                                </div>
                            </div>
                            <!-- single address end -->
                            <!-- single address -->
                            <div class="single-address">
                                <div class="icon">
                                    <i class="fas fa-headset"></i>
                                </div>
                                <div class="discription">
                                    <p>Our Phone</p>
                                    <span>{{OurPhone}}</span>
                                </div>
                            </div>
                            <!-- single address end -->
                        </div>

                    </div>
                </div>
                <!-- footer end area end -->
            </div>
        </div>
        <div class="footer-copyright-four">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="left">
                            <p class="disc" v-html="Copyrights">
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- rts footer style three hear end -->




</template>

<script>
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        name: 'FooterVue',
        components: {
            SocialMediaIconComponent
        },
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        data() {
            let currentYear = new Date().getFullYear();

            return {
                currentYear,
                companyLogo: '',
                companyName: ''
            }
        },
        created() {
            this.getCompanyLogo();
            this.companyName = localStorage.getItem('companyName')
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            }
        }

    }
</script>