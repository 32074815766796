<template>
    <ul class="social_sidebar_internal" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
        <li class="item-list-a" v-for="item in socialmedialinkList">
            <a :href="[item.Url]" target="_blank" :title="item.Title"><i :class="[item.IconUsed]"></i></a>
        </li>
</ul>
</template>


<script>
    export default {
        props: ["companyName", "schema", "socialmedialinkList"]
    }
</script>
