<template>
    <ConfirmPopupComponent :question="question" :header="header" :cancel="cancel" :confirm="confirm" @confirm="onconfirm" @cancel="onCancel" ></ConfirmPopupComponent>
</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        props: ["question", "header", "cancel", "confirm"],
        emits: ["confirm", "cancel"],
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.ConfirmPopupComponent = require('@/layout/Themes/DarkTheme/Views/Shared/ConfirmPopup.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.ConfirmPopupComponent = require('@/layout/Themes/CleanTheme/Views/Shared/ConfirmPopup.vue').default;
            }
            else if (this.themeName == 'Classic') {

                this.$options.components.ConfirmPopupComponent = require('@/layout/Themes/ClassicTheme/Views/Shared/ConfirmPopup.vue').default;
            }
            else if (this.themeName == 'Modern') {

                this.$options.components.ConfirmPopupComponent = require('@/layout/Themes/ModernTheme/Views/Shared/ConfirmPopup.vue').default;
            }
            else {
                this.$options.components.ConfirmPopupComponent = require('@/layout/Themes/DefaultTheme/Views/Shared/ConfirmPopup.vue').default;
            }
        },
        components: {
            ConfirmPopupComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/Shared/ConfirmPopup.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/Shared/ConfirmPopup.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/Shared/ConfirmPopup.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/Shared/ConfirmPopup.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/Shared/ConfirmPopup.vue');
                }
            }
        },
        data() {
            let themeName = localStorage.getItem('theme');
            return {
                themeName,
            }
        },
        created() {
            document.body.style.overflow = 'hidden';
        },
        methods: {
            onCancel() {
                this.$emit('cancel')
                document.body.style.overflow = 'visible';
            },
            onconfirm() {
                this.$emit('confirm')
                document.body.style.overflow = 'visible';
            },

            }
    });

</script>

<style scoped>

.modal-container {
display: flex;
justify-content: center;
align-items: center;
position: fixed !important;
top: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
background-color: #0000008a !important;
z-index: 99;
}

.modal-container .modal-dialog {
width: 100%;
border-radius: 5px;
overflow: hidden;
}

.modal-container .modal-body {
width: 100%;
padding: 20px;
border: none;
text-align: left;
background-color: #fff;
border-radius: 10px;
max-height: 600px !important;
overflow: auto;
}

.modal-container .modal-dialog .modal-header {
padding: 19px;
border-bottom: 1px solid #dee2e6;
}

.modal-container .modal-dialog .modal-footer {
padding: 15px;
display: flex;
border-top: 1px solid #dee2e6;
}

.modal-container .modal-dialog .modal-footer .btn {
width: max-content;
margin-left: 13px;
padding: 7px 21px;
}

.modal-content {
position: relative;
display: flex;
flex-direction: column;
width: 100%;
color: var(--bs-modal-color);
pointer-events: auto;
background-color: #fff !important;
background-clip: padding-box;
border: 1px solid #fff;
border-radius: 6px;
outline: 0;
padding: 20px;
}

.modal-header {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-negative: 0;
flex-shrink: 0;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
padding: 1rem 1rem;
border-bottom: 1px solid #dee2e6;
border-top-left-radius: calc(0.3rem - 1px);
border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
padding: 0.5rem 0.5rem;
margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
margin-bottom: 0;
line-height: 1.5;
}

</style>