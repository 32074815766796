<template>

    <ul class="social-link" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
        <li v-for="item in socialmedialinkList">
            <a :href="[item.Url]" target="_blank"><i :class="[item.IconUsed]"></i></a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: ["companyName", "schema", "socialmedialinkList"]
    }
</script>