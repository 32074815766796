<template>
    <SocialMediaIconComponent :companyName="companyName"
                              :schema="schema"
                              :socialmedialinkList="socialmedialinkList">
    </SocialMediaIconComponent>
</template>

<script>
    import UserFrontendService from '../../Services/frontEndService.js';
    export default {
        name: 'SocialMediaIcons',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.SocialMediaIconComponent = require('@/layout/Themes/DarkTheme/Views/SocialMediaIcon/socialMediaIcon.vue').default;
            }
            else if (this.themeName == 'Clean') {
                this.$options.components.SocialMediaIconComponent = require('@/layout/Themes/CleanTheme/Views/SocialMediaIcon/socialMediaIcon.vue').default;
            }
            else if (this.themeName == 'Classic') {
                this.$options.components.SocialMediaIconComponent = require('@/layout/Themes/ClassicTheme/Views/SocialMediaIcon/socialMediaIcon.vue').default;
            }
            else if (this.themeName == 'Modern') {
                this.$options.components.SocialMediaIconComponent = require('@/layout/Themes/ModernTheme/Views/SocialMediaIcon/socialMediaIcon.vue').default;
            }
            else {
                this.$options.components.SocialMediaIconComponent = require('@/layout/Themes/DefaultTheme/Views/SocialMediaIcon/socialMediaIcon.vue').default;
            }
        },
        components: {
            SocialMedaiIconComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/SocialMediaIcon/socialMediaIcon.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/SocialMediaIcon/socialMediaIcon.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/SocialMediaIcon/socialMediaIcon.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/SocialMediaIcon/socialMediaIcon.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/SocialMediaIcon/socialMediaIcon.vue');
                }
            },
        },
        data() {
            let themeName = localStorage.getItem('theme');
            const searchModel = {
                searchItem: null,
                status: -1,
                sortColumn: 'CreatedOn',
                sortDir: 'desc',
                pageIndex: 0,
                pageSize: 10,
                userId: localStorage.getItem("comp-userId"),
                companyId: localStorage.getItem("companyId")
            }
            return {
                themeName,
                socialmedialinkList: [],
                searchModel
            }
        },
        created() {
            this.getListOfSocialLinks();
        },
        methods: {
            getListOfSocialLinks() {
                this.isLoding = true;
                UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, this.searchModel.userId, this.searchModel.companyId , 1).then(response => {
                    this.socialmedialinkList = response.data.data;
                })
                setTimeout(() => { this.isLoding = false; }, 1000);

            },
        }
    }

</script>
