<template>
    <section class="cta-area cta-bg news newsLetter-bg">
        <div class="overlay"></div>
        <div class="container pd-100">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="section-title cta-title">
                        <p>Newsletter</p>
                        <h2>Sign up for our newsletter and stay up to date on the latest properties available</h2>
                        <Form autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}" class="bg-body d-flex">
                            <Field type="text" name="emailname" v-model="emailValuecomp"
                                   :class="{ 'form-control' : true ,'is-invalid': errors.emailname }"
                                   placeholder="Enter Email" />
                            <div class="invalid-feedback">{{errors.emailname}}</div>
                            <button type="submit" class="btn btn-dark"><i class="fas fa-paper-plane"></i> Subscribe</button>
                        </Form>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    export default {
        components: {
            Form, Field
        },
        props: ['schema'],
        emits: ["submitNewsLetter"],
        data() {
            return {
                emailValuecomp: ''
            }
        },
        methods: {
            onSubmit() {
                this.$emit("submitNewsLetter", this.emailValuecomp);
            }
        }
    }
</script>