<template>    
    <div class="footer-newsletter mb-50 pd-50">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title mb-30 wow fadeInLeft">
                    <h2>Subscribe Our Newsletter</h2>
                    <p>Sign up for our newsletter and stay up to date on the latest properties available</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div v-if="showForm" class="newsletter-form mb-30 wow fadeInRight">
                    <Form ref="form1" autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}">
                        <div class="form_group">
                            <label><i class="far fa-envelope-open"></i></label>
                            <Field type="text" name="emailname" v-model="emailValuecomp.EmailValue"
                                   :class="{ 'form_control' : true ,'is-invalid': errors.emailname }"
                                   placeholder="Email Address" />
                            <div class="invalid-feedback">{{errors.emailname}}</div>
                            <button type="submit" class="main-btn btn-green">Subscribe<i class="fa fa-angle-double-right"></i></button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    export default {
        components: {
            Form, Field
        },
        props: ['schema',"emailValuecomp"],
        emits: ["submitNewsLetter"],
        data() {
            return {
                showForm: true
            }
        },
        methods: {
            onSubmit() {              
                this.showForm = false;                
                this.$emit("submitNewsLetter", this.emailValuecomp.EmailValue);
            }            
        }
    }
</script>