/**
* Template Name: EstateAgency - v4.9.0
* Template URL: https://bootstrapmade.com/real-estate-agency-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/


import $ from 'jquery'
$(document).ready(function () {
    
    setTimeout(function () {
    yearclick(1);

    }, 2000);

});


//router-link-active router-link-exact-active nav-link
$('body').on('click', '.nav-item', function () {
    setTimeout(function () {
        yearclick(1);

    }, 2000);
})
function yearclick(chk) {

    if (chk == '1') {
       
        const box1 = document.getElementsByClassName('yearlyprice1')[0];
        if (box1 != null) {
            box1.style.visibility = 'hidden';
            box1.style.height = "0px";
        }


        const box2 = document.getElementsByClassName('yearlyprice3')[0];
        if (box2 != null) {
            box2.style.visibility = 'hidden';
            box2.style.height = "0px";
        }


        const box3 = document.getElementsByClassName('yearlyprice2')[0];
        if (box3 != null) {
            box3.style.visibility = 'visible';
            box3.style.height = "auto";
        }

        const box4 = document.getElementsByClassName('yearlyprice4')[0];
        if (box4 != null) {
            box4.style.visibility = 'visible';
            box4.style.height = "auto";
        }
    }
    else {
       
        const box1 = document.getElementsByClassName('yearlyprice1')[0];
        if (box1 != null) {
            box1.style.visibility = 'visible';
        }

        const box2 = document.getElementsByClassName('yearlyprice3')[0];
        if (box2 != null) {
            box2.style.visibility = 'visible';
        }

        const box3 = document.getElementsByClassName('yearlyprice2')[0];
        if (box3 != null) {
            box3.style.visibility = 'hidden';
            box3.style.height = "0px";
        }

        const box4 = document.getElementsByClassName('yearlyprice4')[0];
        if (box4 != null) {
            box4.style.visibility = 'hidden';
            box4.style.height = "0px";
        }
    }
}
$('body').on('click', '#yearly', function () {
    const box1 = document.getElementsByClassName('yearlyprice1')[0];
    if (box1 != null) {
        box1.style.visibility = 'visible';
        box1.style.height = "auto";
    }

    const box2 = document.getElementsByClassName('yearlyprice3')[0];
    if (box2 != null) {
        box2.style.visibility = 'visible';
        box2.style.height = "auto";
    }

    const box3 = document.getElementsByClassName('yearlyprice2')[0];
    if (box3 != null) {
        box3.style.visibility = 'hidden';
        box3.style.height = "0px";
    }

    const box4 = document.getElementsByClassName('yearlyprice4')[0];
    if (box4 != null) {
        box4.style.visibility = 'hidden';
        box4.style.height = "0px";
    }

    $('#yearly').addClass("active");
    $('#monthly ').removeClass("active");

});


$('body').on('click', '#monthly', function () {
    const box1 = document.getElementsByClassName('yearlyprice1')[0];
    if (box1 != null) {
        box1.style.visibility = 'hidden';
        box1.style.height = "0px";
    }


    const box2 = document.getElementsByClassName('yearlyprice3')[0];
    if (box2 != null) {
        box2.style.visibility = 'hidden';
        box2.style.height = "0px";
    }


    const box3 = document.getElementsByClassName('yearlyprice2')[0];
    if (box3 != null) {
        box3.style.visibility = 'visible';
        box3.style.height = "auto";
    }

    const box4 = document.getElementsByClassName('yearlyprice4')[0];
    if (box4 != null) {
        box4.style.visibility = 'visible';
        box4.style.height = "auto";
    }

    $('#monthly').addClass("active");
    $('#yearly ').removeClass("active");

    
});
(function () {
    "use strict";


    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
            if (all) {
                selectEl.forEach(e => e.addEventListener(type, listener))
            } else {
                selectEl.addEventListener(type, listener)
            }
        }
    }






    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    /**
     * Toggle .navbar-reduce
     */
    let selectHNavbar = select('.navbar-default')
    if (selectHNavbar) {
        onscroll(document, () => {
            if (window.scrollY > 100) {
                selectHNavbar.classList.add('navbar-reduce')
                selectHNavbar.classList.remove('navbar-trans')
            } else {
                selectHNavbar.classList.remove('navbar-reduce')
                selectHNavbar.classList.add('navbar-trans')
            }
        })
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     * Preloader
     */
    let preloader = select('#preloader');
    if (preloader) {
        window.addEventListener('load', () => {
            preloader.remove()
        });
    }

    /**
     * Search window open/close
     */
    let body = select('body');
    on('click', '.navbar-toggle-box', function (e) {
        e.preventDefault()
        body.classList.add('box-collapse-open')
        body.classList.remove('box-collapse-closed')
    })

    on('click', '.close-box-collapse', function (e) {
        e.preventDefault()
        body.classList.remove('box-collapse-open')
        body.classList.add('box-collapse-closed')
    })

    /**
     * Intro Carousel
     */
    new Swiper('.intro-carousel', {
        speed: 600,
        loop: false,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        }
    });

    /**
     * Property carousel
     */
    new Swiper('#property-carousel', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.propery-carousel-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },

            1200: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    });

    /**
     * News carousel
     */
    new Swiper('#news-carousel', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.news-carousel-pagination',
            type: 'bullets',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },

            1200: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    });

    /**
     * Testimonial carousel
     */
    new Swiper('#testimonial-carousel', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.testimonial-carousel-pagination',
            type: 'bullets',
            clickable: true
        }
    });

    /**
     * Property Single carousel
     */
    new Swiper('#property-single-carousel', {
        speed: 600,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.property-single-carousel-pagination',
            type: 'bullets',
            clickable: true
        }
    });






})()