<template>
    <footer class="footer-default dark-black-bg text-white">
        <div class="container">

            <!--  newsletter Section -->
            <newsletter></newsletter>
            <!--  End newsletter Section -->

            <div class="footer-widget-area ">
                <div class="row">
                    <div class="col-xl-4 col-lg-3 col-md-12">
                        <div class="footer-widget about-company-widget mb-40 wow fadeInDown">
                            <router-link :to="{ name: 'HomePage'}" class="footer-logo">
                                <img :src="companyLogo" alt="Brand Logo">
                            </router-link>
                            <p>
                                {{FooterLogoDescription}}                                
                            </p>
                            <SocialMediaIconComponent></SocialMediaIconComponent>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="footer-widget footer-nav-widget pl-lg-55 mb-40 wow fadeInUp">
                            <h4 class="widget-title">Links</h4>
                            <div class="footer-nav-content">
                                <ul class="footer-nav">
                                    <li class="item-list-a" v-for="link in footerNavLinks">
                                        <router-link class="nav-link"  :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-xl-2 col-lg-3 col-md-4">
                        <div class="footer-widget footer-nav-widget pl-lg-20 mb-40 wow fadeInDown">
                            <h4 class="widget-title">Services</h4>
                            <div class="footer-nav-content">
                                <ul class="footer-nav">
                                    <li class="item-list-a" v-for="serviceLink in serviceLinks">
                                        <router-link :to="{ name: serviceLink.routeUrl}">{{serviceLink.displayText}}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>-->

                    <div class="col-xl-4 col-lg-3 col-md-4">
                        <div class="footer-widget contact-info-widget pl-lg-100 mb-40 wow fadeInDown">
                            <div class="contact-info-box mb-35">
                                <h4 class="title">Location</h4>
                                <p><i class="fa fa-map-marker-alt"></i><span>{{OurAddress}}</span></p>
                            </div>
                            <div class="contact-info-box mb-35">
                                <h4 class="title">Contact</h4>
                                <p><i class="fa fa-envelope"></i><a :href="'mailto:'+OurEmail">{{OurEmail}}</a></p>
                                <p><i class="fa fa-phone"></i><a :href="'tel:'+ OurPhone"> {{OurPhone}}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!--=== Copyright Area ===-->
            <div class="copyright-area">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="copyright-text">
                            <p v-html="Copyrights"></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    <a href="#"  class="back-to-top" :style="(show)?ShowCss:hideCss"><i class="fa fa-angle-up"></i></a>
</template>

<script>
    import newsletter from '@/views/NewsLetter/newsletter.vue'
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        name: 'FooterVue',
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        components: {
            newsletter,
            SocialMediaIconComponent
        },
        data() {
            let currentYear = new Date().getFullYear();

            return {

                show: false,
                currentYear,
                companyLogo: '',
                companyName: '',
                hideCss:{
                    display:'none'
                    },
                
                ShowCss:{
                    display:'inline'
                    },
            }
        },
        created() {
            this.getCompanyLogo();
            this.companyName = localStorage.getItem('companyName')
        },
         mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            },
            updateScroll() {
                let windoWidth = window.scrollY;
                if (windoWidth > 500) {
                    this.show = true;
                } else {
                    this.show = false;
                }
            }

        }

    }
</script>