<template>
    <div class="newsletter pd-100">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class=" position-relative">
                        <div class="row ">
                            <div class="col-xl-7 col-lg-10 col-md-12 text-center mx-auto py-md-5">
                                <!-- Title -->
                                <h2 class="mb-4">Subscribe &amp; Get Special Discount!</h2>
                                <p class="mb-4">Sign up for our newsletter and stay up to date on the latest properties available.</p>
                                <!-- Form -->
                                <Form autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}" class="bg-body d-flex">
                                    <Field type="text" name="emailname" v-model="emailValuecomp"
                                           class="form-control"
                                           :class="{ 'form_control' : true ,'is-invalid': errors.emailname }"
                                           placeholder="Enter Email" />
                                    <div class="invalid-feedback">{{errors.emailname}}</div>
                                    <button type="submit" class="btn btn-dark"><i class="fas fa-paper-plane"></i></button>
                                </Form>
                            </div>
                        </div> <!-- Row END -->
                    </div>
                </div>
            </div> <!-- Row END -->
        </div>
    </div>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    export default {
        components: {
            Form, Field
        },
        props: ['schema'],
        emits: ["submitNewsLetter"],
        data() {
            return {
                emailValuecomp: ''
            }
        },
        methods: {
            onSubmit() {
                this.$emit("submitNewsLetter", this.emailValuecomp);
            }
        }
    }
</script>