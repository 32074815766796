<template>
    <input type="text" v-model="address" id="AutoComplete" style="margin-bottom: 20px" />
    <input type="text" id="AutoCompleteS" style="margin-bottom: 20px" />
    <!-- <button type="button" @click="locatorButtonPress">Get Location</button> -->
    <!-- <GoogleMap
      api-key="AIzaSyBIhYktcWxSwq2NMh7YWOid1YSsPpK9sJU"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="15"
    >
      <Marker :options="markerOptions" />
    </GoogleMap> -->
    <div id="map" style="width: 100%; height: 500px"></div>
</template>

<script>
//import { GoogleMap, Marker } from "vue3-google-map";
import { ref, onMounted } from "vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    //GoogleMap,
    //Marker,
  },
  setup() {
    const address = ref("");
    const center = {
      lat: 40.689247,
      lng: -74.044502,
    };
    const markerOptions = {
      position: center,
      label: "L",
      title: "LADY LIBERTY",
    };
    // const locatorButtonPress = () => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         getAddressFrom(position.coords.latitude, position.coords.longitude);
    //         showUserLocationOnMap(position.coords.latitude, position.coords.longitude);
    //       },
    //       (error) => {
    //       }
    //     );
    //   } else {
    //   }
    // };

    const getAddressFrom = (lat, long) => {
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyAJdx4gmhGKWjPTBBVk5riFgpbE0lGS-xM"
        )
        .then((response) => {
          if (response.data.error_message) {
          } else {
            address.value = response.data.results[0].formatted_address;
          }
        })
        .catch((error) => {
        });
    };

    const showUserLocationOnMap = (latitude, longitude) => {
      // create a Map Object
      let map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: new window.google.maps.LatLng(latitude, longitude),
        maptypeId: window.google.maps.MapTypeId.ROADMAP,
      });

      // Add Marker
      new window.google.maps.Marker({
        position: new window.google.maps.LatLng(latitude, longitude),
        map: map,
      });
    };

    const showUserLocationOnMapData = (latitude, longitude) => {
      // create a Map Object
      let map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: new window.google.maps.LatLng(latitude, longitude),
        maptypeId: window.google.maps.MapTypeId.ROADMAP,
      });

      // Add Marker
      new window.google.maps.Marker({
        position: new window.google.maps.LatLng(latitude, longitude),
        map: map,
      });
    };

    onMounted(() => {
      //window.google.maps.places
      let autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("AutoComplete")
      );
      let lat = 40.689247;
      let lng = -74.044502;
      showUserLocationOnMapData(lat, lng);
      autocomplete.addListener("place_changed", () => {
        let place = autocomplete.getPlace();

        for (let component of place.address_components) {
        }
        showUserLocationOnMap(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
      });
    });

    return {
      center,
      markerOptions,
      //locatorButtonPress,
      getAddressFrom,
      address,
      showUserLocationOnMap,
      showUserLocationOnMapData,
    };
  },
};
</script>

<style>
        /* #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 60px;
    } */
        .pac-item {
            cursor: pointer;
        }
        /* input[type="text"] {
      background-color: white;
      background-position: 10px 10px;
      background-repeat: no-repeat;
      padding-left: 40px;
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
    } */
</style>

