import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/layout.vue'
import frontEndService from '../Services/frontEndService.js'
import helpers from '../helpers/filters'


const routes = [
    {
        path: '',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '',
                name: 'HomePageDefault',
                redirect: to => {
                    return { path: `/Home` }
                }
            },
            {
                path: '/Home',
                name: 'HomePage',
                component: () => import('@/views/Home/HomePage.vue')
            },
            {
                path: 'mobileDashboard',
                name: 'mobileDashboard',
                component: () => import('@/views/MobileDashboard/mobileDashboard.vue')
            },
            {
                path: 'Aboutus',
                name: 'Aboutus',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: ':url',
                name: 'dynamicPage',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'Howitworks',
                name: 'Howitworks',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'AssetLegalTerms/:Id',
                name: 'AssetLegalTerms',
                component: () => import('@/views/AssetLegalTerms/AssetLegalTerms.vue')
            },
            {
                path: 'PrivacyPolicy',
                name: 'PrivacyPolicy',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'TermsandConditions',
                name: 'TermsandConditions',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'Scheduling',
                name: 'Scheduling',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'Listings',
                name: 'Listings',
                component: () => import('@/views/ManageAsset/assetListing.vue')
            },
            {
                path: 'AssetDetail/:id',
                name: 'AssetDetail',
                component: () => import('@/views/ManageAsset/assetDetail.vue')
            },
            {
                path: 'AssetDetail',
                name: 'AssetDetails',
                component: () => import('@/views/ManageAsset/assetDetail.vue')
            },
            {
                path: 'sell',
                name: 'sell',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: 'ambassador',
                name: 'ambassador',
                component: () => import('@/views/ManageMembership/membershipList.vue')
            },
            {
                path: 'membershipdetail/:id',
                name: 'membershipdetail',
                component: () => import('@/views/ManageMembership/membershipdetail.vue')
            },
            {
                path: 'thankyou',
                name: 'thankyou',
                component: () => import('@/views/ThankYou/thankyou.vue')
            },
            {
                path: 'contactus',
                name: 'ContactUs',
                component: () => import('@/views/contactus/contactus')
            },
            {
                path: 'Location',
                name: 'Location',
                component: () => import('@/views/contactus/contactus')
            },
            {
                path: 'Onsale',
                name: 'Onsale',
                component: () => import('@/views/ViewAllCategory/ViewAllCategory.vue')
            },

            {
                path: 'pressnews',
                name: 'pressnews',
                component: () => import('@/views/News/news.vue')
            },
            {
                path: 'pressnewsdetail/:id',
                name: 'Press News Detail',
                component: () => import('@/views/News/newsdetail.vue')
            },
            {
                path: 'unsubscribenewsletter/:email',
                name: 'Unsubscribe Newsletter',
                component: () => import('@/views/shared/UnsubscribeNewsletter/unsubscribenewsletter.vue')
            },
            {
                path: 'unsubscribe/:email/:companyId',
                name: 'Unsubscribe',
                component: () => import('@/views/Unsubscribe/unsubscribe.vue')
            },
            {
                path: 'resetpassword',
                name: 'Set Password',
                component: () => import('@/views/SetPassword/setPassword.vue')
            },

            {
                path: 'assetcomparedetails',
                name: 'assetcomparedetails',
                component: () => import('@/views/ManageAsset/assetCompareDetails.vue')
            },
            {
                path: 'reviewslisting',
                name: 'ReviewsListing',
                component: () => import('@/views/ManageReviews/reviewsListing.vue')
            },
            {
                path: 'FAQ',
                name: 'FAQ',
                component: () => import('@/views/FrequentlyAskedQuestions/frequentlyAskedQuestionsAnswer.vue')
            },

            {
                path: 'clientRegistration',
                name: 'ClientRegistration',
                component: () => import('@/views/Registration/clientRegistration.vue')
            },
            {
                path: 'SignupPage',
                name: 'SignupPage',
                component: () => import('@/views/SignUpPage/SignupPage.vue')
            },
            {
                path: 'ForgotPassword',
                name: 'ForgotPassword',
                component: () => import('@/views/ForgotPassword/ForgotPassword.vue')
            },
            {
                path: 'ForgotUsername',
                name: 'ForgotUsername',
                component: () => import('@/views/ForgotUsername/ForgotUsername.vue')
            },
            {
                path: '/account/resetpassword',
                name: 'Resetpasswordmodel',
                component: () => import('@/views/ResetPasswordmodel/Resetpasswordmodel.vue')
            },
            {
                path: 'ForgotPassword/:id',
                name: 'ForgotPasswords',
                component: () => import('@/views/ForgotPassword/ForgotPassword.vue')
            },

          
            {
                path: 'Login',
                name: 'Login',
                component: () => import('@/views/Login/login.vue')
            },
            {
                path: 'Testimonials',
                name: 'Testimonials',
                component: () => import('@/views/Testimonial/testimonialList.vue')
            },
            {
                path: 'Blog',
                name: 'Blog',
                component: () => import('@/views/Blog/blogList.vue')
            },
            {
                path: 'blogdetail/:id',
                name: 'Blog Detail',
                component: () => import('@/views/Blog/blogDetail.vue')
            },
            {
                path: 'VeteransVacation',
                name: 'VeteransVacation',
                component: () => import('@/views/VeteransVacations/veteransvacations.vue')
            },
            {
                path: 'AssetConfirm',
                name: 'Asset Confirm',
                component: () => import('@/views/AssetBuyConfirmation/Thankyou.vue')
            },
            {
                path: 'AssetRentConfirm',
                name: 'Asset Rent Confirm',
                component: () => import('@/views/AssetRentConfirmation/Thankyou.vue')
            },
            {
                path: 'iconic',
                name: 'Iconic Page',
                component: () => import('@/views/Iconic/iconic.vue')
            },
            {
                path: 'LandingPageThankYou',
                name: 'Landing Page Thank You',
                component: () => import('@/views/LandingPageThankYou/landingpagethankyou.vue')
            },
            {
                path: 'FrexiiCRM',
                name: 'FrexiiCRM',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '10311Exchange',
                name: '10311Exchange',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Cart/:Id',
                name: 'Cart',
                component: () => import('@/views/Cart/Cart.vue')
            },
            {
                path: 'Cart',
                name: 'Carts',
                component: () => import('@/views/Cart/Cart.vue')
            },
            {
                path: '/ProductDetail/:Id',
                name: 'ProductDetail',
                component: () => import('@/views/ProductDetail/ProductDetail.vue')
            },
            {
                path: '/Product/:Id',
                name: 'Product',
                component: () => import('@/views/Product/Product.vue')

            },
            {
                path: '/ViewAllcategories',
                name: 'ViewAllcategories',
                component: () => import('@/views/ViewAllCategory/ViewAllCategory.vue')
            },
            {
                path: '/Products',
                name: 'Products',
                component: () => import('@/views/ViewAllCategory/ViewAllCategory.vue')
            },
            {
                path: 'myprofile',
                name: 'myprofile',
                component: () => import('@/views/AuthorizedUserView/MyProfile/myProfile.vue')
            },
            {
                path: 'learning',
                name: 'learning',
                component: () => import('@/views/Learning/learning.vue')
            },

            {
                path: 'setPassword',
                name: 'setPassword',
                component: () => import('@/views/SetPassword/setPassword.vue')
            },
            {
                path: 'shippingadd',
                name: 'shippingadd',
                component: () => import('@/views/shippingadd/shippingadd.vue')
            },
            {
                path: 'Addbilling',
                name: 'Addbilling',
                component: () => import('@/views/Addbilling/Addbilling.vue')
            },
            {
                path: 'AddBill/:addressID',
                name: 'Addbillings',
                component: () => import('@/views/Addbilling/Addbilling.vue')
            },

            {
                path: 'Dashboard',
                name: 'Dashboard',
                component: () => import('../layout/Themes/DefaultTheme/Views/Dashboard/DashbordPage.vue')
            },
            //{
            //    path: '/dashboard',
            //    name: 'dashboard',
            //    component: () => import('@/views/Dashboard/DashboardPage.vue')
            //},

            {
                path: '/ProceedToCheckOut',
                name: 'ProceedToCheckOut',
                component: () => import('@/views/ProceedToCheckOut/ProceedToCheckOut.vue')
            },  
            {
                path: '/Shipping',
                name: 'Shipping',
                component: () => import('@/views/Shop/Shipping.vue')
            },
            {
                path: '/Confirmation',
                name: 'Confirmation',
                component: () => import('@/views/Confirmation/Confirmation.vue')
            },
            {
                path: '/OrderConfirmation',
                name: 'OrderConfirmation',
                component: () => import('@/views/OrderConfirmation/OrderConfirmation.vue')

            },
            {
                path: '/Oders',
                name: 'Oders',
                component: () => import('@/views/Orders/Orders.vue')
            },
            {
                path: '/DeleteAccount',
                name: 'DeleteAccount',
                component: () => import('@/views/DeleteUserAccount/DeleteAccount.vue')
            },

            /* Static page URLS*/
            //{
            //    path: '/OrderTracker',
            //    name: 'OrderTracker',
            //    component: () => import('@/views/StaticPages/OrderTracker.vue')
            //},
            //{
            //    path: '/ReturnPolicy',
            //    name: 'ReturnPolicy',
            //    component: () => import('@/views/StaticPages/ReturnPolicy.vue')
            //},
            //{
            //    path: '/DeliveryOptions',
            //    name: 'DeliveryOptions',
            //    component: () => import('@/views/StaticPages/DeliveryOptions.vue')
            //},
            {
                path: '/TaxExemptionss',
                name: 'TaxExemptionss',
                component: () => import('@/views/StaticPages/TaxExemptions.vue')
            },


            /*-----------*/
            //{
            //    path: '/OrderConfirmationdesign',
            //    name: 'OrderConfirmationdesign',
            //    component: () => import('@/views/OrderConfirmation/OrderConfirmationdesign.vue')
            //},
            //{
            //    path: '/Confirmationdesign',
            //    name: 'Confirmationdesign',
            //    component: () => import('@/views/Confirmation/Confirmationdesign.vue')
            //},
            //{
            //    path: '/ProceedToCheckOutdesign',
            //    name: 'ProceedToCheckOutdesign',
            //    component: () => import('@/views/ProceedToCheckOut/ProceedToCheckOutdesign.vue')
            //},
            //{
            //    path: '/ProductDesign',
            //    name: 'ProductDesign',
            //    component: () => import('@/views/Product/ProductDesign.vue')
            //},

            //{
            //    path: '/Cartdesign',
            //    name: 'Cartdesign',
            //    component: () => import('@/views/Cart/Cartdesign.vue')
            //},

            //{
            //    path: '/Shippingdesign',
            //    name: 'Shippingdesign',
            //    component: () => import('@/views/Shop/Shippingdesign.vue')
            //},


            {
                path: '/Productdetaildesign',
                name: 'Productdetaildesign',
                component: () => import('@/views/ProductDetail/Productdetaildesign.vue')
            },
            {
                path: '/orderdetails/:Id',
                name: 'orderdetails',
                component: () =>
                    import('@/views/orderdetails/orderdetails.vue'),
            },
            {
                path: '/OrderSubscriptionList',
                name: 'OrderSubscriptionList',
                component: () => import('@/views/ManageOrderSubscriptionList/OrderSubscriptionList.vue')
            },
            //{
            //    path: '/Listings',
            //    name: 'Listings',
            //    component: () => import('@/views/ViewAllCategory/ViewAllCategory.vue')
            //},

            /* Static Page Urls ends*/

        ]
    },
    {
        path: '/logoutRedirect/:origin',
        name: 'logoutRedirect',
        component: () => import('../layout/LogoutRedirect.vue')
    },
    {
        path: '/errorPage',
        name: 'errorPage',
        component: () => import('@/views/ErrorPage/errorPage.vue')
    },

];

//Global Arrays For Dynamic Menu
let HeaderNavlinks = []
let FooterNavlinks1 = []
let FooterNavlinks2 = []
let FooterNavlinks3 = []
let FooterworkusNavLinks = []
const router = createRouter({
    history: createWebHistory(),
    routes, HeaderNavlinks, FooterNavlinks1, FooterNavlinks2, FooterNavlinks3,  FooterworkusNavLinks
})


function createMenuItems(menuList) {
    return menuList.map(item => {
        const matchingRoute = routes[0].children.find(route => route.name === item.routeUrl);
        return {
            routeUrl: matchingRoute ? item.routeUrl : 'dynamicPage',
            displayText: item.displayText,
            childMenu: item.ChildMenu,
            params: matchingRoute ? null : { url: item.routeUrl }
        };
    });
}

async function setMenuItems(userId, companyId) {
    const response = await frontEndService.GetHeaderFooterData(userId, companyId);
    const HeaderFooterList = JSON.parse(Object.values(response.data));

    HeaderNavlinks.push(JSON.stringify(createMenuItems(HeaderFooterList.HeaderMenuListing)));
    FooterNavlinks1.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing1)));
    FooterNavlinks2.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing2)));
    FooterNavlinks3.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing3)));
    FooterworkusNavLinks.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuworkusListing)));

    console.log('menu list', HeaderFooterList.HeaderMenuListing);
}

router.beforeEach(async (to, from, next) => {

    const specialRoutes = 'Home'// ['errorPage', 'logoutRedirect'];
    if (specialRoutes.includes(to.name)) {
        return next();
    }
    let companyDomainName = location.host; //'BrooksPremiumRealEstate.beenee.com'; //'kylefeedstore.beenee.com';//location.host;'SidpanRealEstate.beenee.com'; //
    //const companyUniqueName = location.host.split('.').shift();
    //let lastIndex = to.path.split('').findIndex((x, i) => { return x == '/' && i > 1 })
    //let companyUniqueName = to.path.substring(1, (lastIndex == -1) ? to.path.length : lastIndex);
    let companyUniqueName = to.path.split('/')[1] || to.path.substring(1);
    //if (from.path == '/') {
    //     companyUniqueName = to.path.split('/')[1] || to.path.substring(1);
    //}
    //else {
    //     companyUniqueName = from.path.split('/')[1] || from.path.substring(1);
    //}

    try {
        if (helpers.IsNull(companyDomainName, true, null) == null) {
            throw new Error('Invalid company data');
        }
        if (to.matched.length == 0) {
            throw new Error('Invalid Page');
        }
        if (companyDomainName == 'web.beenee.com' || companyDomainName == 'beenee.com') {
            window.location.href = 'https://www.beeneecompany.zorbis.com/';
        }
        const companyDetailsResponse = await frontEndService.GetCompanyDetailsByDomainOrName(companyDomainName, companyUniqueName);

        if (companyDetailsResponse.data.statusCode !== 200) {
            throw new Error('Company details not found');
        }

        const companyData = companyDetailsResponse.data.result;
        await SetData(companyData, to.name, companyUniqueName);

        await setMenuItems(companyData.userId, companyData.companyId);

        await beforeRouteHitMethods(to);
        //if (to.hash == '' || to.hash == null) {
        //    window.scrollTo(0, 0);
        //}
        await GetmetaData(to);
        return next();

    }
    catch (error) {
        console.error(error);
        return next('/errorPage');
    }
})

async function SetData(companyData, title, companyUniqueName) {
    debugger

    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('comp-userId');
    localStorage.removeItem('companyURL');
    localStorage.removeItem('companyLogo');
    localStorage.removeItem('theme');
    localStorage.removeItem('companyAddress');
    localStorage.removeItem('showAssetViewIcon');
    localStorage.removeItem('companyDomainName');

    localStorage.setItem('companyId', companyData.companyId);
    localStorage.setItem('companyDomainName', companyData.companyDomainName);
    localStorage.setItem('companyName', companyData.companyName);
    localStorage.setItem('comp-userId', companyData.userId);
    localStorage.setItem('companyURL', companyUniqueName);
    localStorage.setItem('companyLogo', companyData.companyPrimaryLogo ? companyData.companyPrimaryLogo : companyData.companySecondaryLogo);
    localStorage.setItem('companySecondaryLogo', companyData.companySecondaryLogo ? companyData.companySecondaryLogo : companyData.companyPrimaryLogo);
    localStorage.setItem('theme', "Default"); //companyData.theme'
    localStorage.setItem('companyAddress', companyData.companyAddress);
    localStorage.setItem('showAssetViewIcon', companyData.assetViews);

    console.log("logo  details ", companyData.companyPrimaryLogo ? companyData.companyPrimaryLogo : companyData.companySecondaryLogo);

    //Check for login in user belong to current company or not 
    if (localStorage.getItem('userinfo') != null && localStorage.getItem('userinfo') != undefined) {
        let userDataJSON = JSON.parse(localStorage.getItem('userinfo'));
        let companyId = userDataJSON.data ? userDataJSON.data.companyId : userDataJSON.companyId;
        if (companyId != localStorage.getItem('companyId').toLowerCase()) {
            localStorage.removeItem("comp-userId");
        }
    }

    if (title == 'undefinded' || typeof title == "undefined") {
        title = ''
    }
    document.title = `${companyData.companyName} | ` + `${title}`;
    return true


}

// this fuction will check for side compare bar close fucntionality 
async function beforeRouteHitMethods(to) {
    var compareElement = document.getElementById('comparisonblock');
    if (to.name.indexOf('HomePage') == 0 || to.name.indexOf('Listings') == 0) {
    }
    else {
        if (compareElement != null) {
            compareElement.classList.remove('open');
        }
    }
    return
}

async function GetmetaData(to) {

    let metaModel = {
        PageTitle: to.name,
        PageUrl: to.href.replace('/', ''),
        companyId: localStorage.getItem('companyId')
    }
    const response = await frontEndService.GetdynamicMetadata(metaModel);
    document.getElementById('keyword').setAttribute("content", response.data.MetaKeyword)
    document.getElementById('description').setAttribute("content", response.data.MetaDescription)
    document.getElementById('title').setAttribute("content", response.data.MetaAuthor)
    var screen = window.outerWidth;
    if (screen > 1080) {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1")
    } else {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1, user-scalable='no'")
    }
}


export default router