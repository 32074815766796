<template>
    <div class="col-xl-3 col-md-6 col-sm-12 col-12">
        <div class="footer-widget footer-newsletter-widget">
            <h4 class="footer-title">Newsletter</h4>
            <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
            <div class="footer-newsletter">
                <Form autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}">
                    <div class="form-group">
                        <Field type="text" name="emailname" v-model="emailValuecomp"
                               :class="{ 'form_control mb-0' : true ,'is-invalid': errors.emailname }"
                               placeholder="Enter Email" />
                        <div class="invalid-feedback" style="position: absolute; left: 0;">{{errors.emailname}}</div>
                        <div class="btn-wrapper">
                            <button class="theme-btn-1 btn" type="submit"><i class="fas fa-location-arrow"></i></button>
                        </div>
                    </div>
                   

                </Form>
            </div>
            <h5 class="mt-30">Follow Us</h5>
            <div class="ltn__social-media mt-20">
                <SocialMediaIconComponent></SocialMediaIconComponent>
            </div>
        </div>
    </div>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        components: {
            Form, Field, SocialMediaIconComponent
        },
        props: ['schema'],
        emits: ["submitNewsLetter"],
        data() {
            return {
                emailValuecomp: ''
            }
        },
        methods: {
            onSubmit() {
                this.$emit("submitNewsLetter", this.emailValuecomp);
            }
        }
    }
</script>