
export default {
//adminPortalUrl: 'http://localhost:8081/',
//adminPortalUrl: 'https://App.beenee.com/',
//adminPortalUrl: 'https://devportal.beenee.com/',

//adminPortalUrl: 'https://dc2Portal.beenee.com/',
    //For Live
    adminPortalUrl: 'https://beeneecompany.zorbis.com/',
    //For QA
    //adminPortalUrl: 'https://QAAdmin.beenee.com/',
    //forStage
    //adminPortalUrl: 'https://stageAdmin.beenee.com/',
encryptkey:'4288943859999624',
encryptiv:'8935076607156689',
};