<template>

    <footer class="footer-bg footer-p pd-100 pb-0">

        <div class="footer-top pb-30">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-6">
                        <div class="footer-widget mb-30">
                            <div class="logo mb-35">
                                <router-link :to="{ path: 'Home'}"><img :src="companyLogo" alt="logo"></router-link>
                            </div>
                            <div class="footer-text mb-80">
                                <p>
                                    {{FooterLogoDescription}}
                                </p>
                            </div>
                            <div class="footer-social">
                                <SocialMediaIconComponent></SocialMediaIconComponent>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-md-3 col-sm-6">
                        <div class="footer-widget mb-30 pl-60">
                            <div class="f-widget-title">
                                <h5>Links</h5>
                            </div>
                            <div class="footer-link">
                                <ul>
                                    <li v-for="link in footerNavLinks">
                                        <router-link class="nav-link" v-if="link.IsDefault==0" :to="{ path: link.routeUrl || { params: {companyUniqueName : companyUniqueName, name: link.routeUrl } } }">{{link.displayText}}</router-link>
                                        <router-link class="nav-link" v-if="link.IsDefault==1" :to="{ name: link.routeUrl}">{{link.displayText}}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
                        <div class="footer-widget mb-30">

                            <div class="footer-link mt-40">
                                <ul>
                                    <li v-for="serviceLink in serviceLinks">
                                        <router-link :to="{ path: serviceLink.routeUrl}">{{serviceLink.displayText}}</router-link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="footer-widget mb-30">
                            <div class="f-widget-title">
                                <h5>Contact Us</h5>
                            </div>
                            <div class="footer-link">
                                <div class="body contact-area">
                                    <!-- single address -->
                                    <div class="single-address">
                                        <div class="icon">
                                            <i class="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div class="discription">
                                            <p>Our Address</p>
                                            <span>{{OurAddress}}</span>
                                        </div>
                                    </div>
                                    <!-- single address end -->
                                    <!-- single address -->
                                    <div class="single-address">
                                        <div class="icon">
                                            <i class="fas fa-at"></i>
                                        </div>
                                        <div class="discription">
                                            <p>Our Email</p>
                                            <span>{{OurEmail}}</span>
                                        </div>
                                    </div>
                                    <!-- single address end -->
                                    <!-- single address -->
                                    <div class="single-address">
                                        <div class="icon">
                                            <i class="fas fa-headset"></i>
                                        </div>
                                        <div class="discription">
                                            <p>Our Phone</p>
                                            <span>{{OurPhone}}</span>
                                        </div>
                                    </div>
                                    <!-- single address end -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="copyright-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="copyright-text text-center">
                            <p v-html="Copyrights"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>


</template>

<script>
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        name: 'FooterVue',
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        components: {
            SocialMediaIconComponent
        },
        data() {
            return {
                companyLogo: '',
                companyName: ''
            }
        },
        created() {
            this.getCompanyLogo();
            this.companyName = localStorage.getItem('companyName')
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            }
        }

    }
</script>