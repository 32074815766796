<template>
    <header class="header-area">
        <!--=== Header Navigation ===-->
        <div :class="(isscrolled)?'sticky header-navigation default-navigation':' header-navigation default-navigation'">
            <div class="nav-overlay"></div>
            <div class="container-fluid">
                <!--=== Primary Menu ===-->
                <div class="primary-menu">

                    <!--=== Site Branding ===-->
                    <div class="site-branding">
                        <router-link :to="{ name: 'HomePage'}" class="brand-logo hwhite">
                            <img :src="companyLogo" alt="Site Logo" style="max-height: 60px; ">
                        </router-link>
                        <router-link :to="{ name: 'HomePage'}" class="brand-logo fsticky">
                            <img :src="companyLogo" alt="Site Logo" style="max-height: 60px ">
                        </router-link>
                        <!--<router-link :to="{ path: 'Home'}" class="brand-logo hwhite"><img :src="companyLogo" alt="Site Logo"></router-link>-->
                        <!--<router-link :to="{ path: 'Home'}" class="brand-logo fsticky"><img :src="companyLogo" alt="Site Logo"></router-link>-->
                    </div>
                    <!--=== Nav Inner Menu ===-->

                    <div class="nav-menu">

                        <!--=== Mobile Logo ===-->
                        <div class="mobile-logo mb-30 d-block d-xl-none text-center">
                            <a href="Javascript:;" class="brand-logo">
                                <img :src="companyLogo" alt="Site Logo" />
                            </a>
                        </div>

                        <!--=== Main Menu ===-->
                        <nav class="main-menu">
                            <ul>
                                <li class="menu-item dropdown" v-for="(link, index)  in NavLinks" :key="index">
                                    <router-link :class="[JSON.parse(link.childMenu).length >0 ? 'dropdown-toggle' : '','nav-link']" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                    <ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                        <li v-for="childLink in JSON.parse(link.childMenu)">
                                            <!--<a class="dropdown-item" :href="childLink.displayText">{childLink.displayText}}</a>-->
                                            <router-link class="nav-link dropdown-item" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>



                    </div>

                    <div class="nav-right-item d-flex align-items-center" v-if="!isUserLoggedIn">
                        <div class="menu-button d-xl-block d-none">
                            <a class="main-btn btn-green" @click="$emit('openLoginPage')">Login/SignUp<i class="fa fa-angle-double-right"></i></a>
                        </div>
                        <div class="navbar-toggler ">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                    </div>
                    <div class="nav-right-item d-flex align-items-center" v-if="isUserLoggedIn">
                        <nav class="main-nav d-xl-block d-none">
                            <ul id="nav">
                                <li class="nav-item dropdown pr-link show">
                                    <router class="nav-link dropdown-toggle" data-toggle="dropdown" href="#/dashboard" aria-expanded="true">My Account</router>
                                    <ul class="dropdown-menu show">
                                        <li>
                                            <a class="nav-link" href="javascript:;" @click="goToPortalDashboard">Go To Portal</a>
                                        </li>
                                        <li>
                                            <a class="login-link" routerlinkactive="active" @click="this.$emit('logout')" ng-reflect-router-link-active="active">Logout</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </nav>

                    </div>
                    <div class="navbar-toggler">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                </div>
            </div>
        </div>


        <div id="header-mobile" class="header-mobile header-navigation d-flex align-items-center" data-sticky="">
            <!--<div class="header-mobile-left">-->
            <!--<div class="navbar-toggler ">
        <span></span>
        <span></span>
        <span></span>
    </div>-->
            <!--<div class="nav-menu">-->
            <!--=== Mobile Logo ===-->
            <!--<div class="mobile-logo mb-30 d-block d-xl-none text-center">
        <a href="Javascript:;" class="brand-logo">
            <img :src="companyLogo" alt="Site Logo" />
        </a>
    </div>-->
            <!--=== Main Menu ===-->
            <!--<nav class="main-menu">
    <ul>
        <li class="menu-item dropdown" v-for="(link, index)  in NavLinks" :key="index">
            <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>-->
            <!--<ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
    <li v-for="childLink in JSON.parse(link.childMenu)">-->
            <!--<a class="dropdown-item" :href="childLink.displayText">{childLink.displayText}}</a>-->
            <!--<router-link class="nav-link" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
        </li>
    </ul>-->
            <!--</li>
            </ul>
        </nav>



    </div>-->
            <!--</div>-->
            <!-- .header-mobile-left -->

            <div class="header-mobile-center flex-grow-1">
                <div class="logo logo-mobile">
                    <a href="Javascript:;" class="brand-logo">
                        <img :src="companyLogo" alt="Site Logo" />
                    </a>
                </div>
            </div>
            <!--<div class="header-mobile-right">
        <button class="btn toggle-button-right" @click="OpenRightMenu">
            <i class="houzez-icon icon-single-neutral-circle ml-1"></i>
        </button>-->
            <!--toggle-button-right-->
            <!--<div class="main-nav navbar slideout-menu slideout-menu-right open " id="nav-mobile1" v-if="IsOpenRightMenu">

            <ul id="mobile-main-nav" class="navbar-nav mobile-navbar-nav" v-if="isUserLoggedIn">

                <side class="mobileMenu"></side>
            </ul>

        </div>
    </div>-->
            <!--.header-mobile-right-->
            <div class="nav-menu">
                <!--=== Mobile Logo ===-->
                <div class="mobile-logo mb-30 d-block d-xl-none text-center">
                    <a href="Javascript:;" class="brand-logo">
                        <img :src="companyLogo" alt="Site Logo" />
                    </a>
                </div>
                <!--=== Main Menu ===-->
                <nav class="main-menu">
                    <ul>
                        <li class="menu-item dropdown" v-for="(link, index)  in NavLinks" :key="index">
                            <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                            <!--<ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                <li v-for="childLink in JSON.parse(link.childMenu)">
                                    <a class="dropdown-item" :href="childLink.displayText">{{childLink.displayText}}</a>
                                    <router-link class="nav-link" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                </li>
                            </ul>-->
                        </li>
                        <li  v-if="!isUserLoggedIn">
                            <a class="main-btn btn-green" @click="$emit('openLoginPage')">Login/SignUp</a>
                        </li>
                        <li  v-if="isUserLoggedIn">
                            <a class="nav-link" href="javascript:;" @click="goToPortalDashboard">Go To Portal</a>
                            <a class="login-link"  @click="this.$emit('logout')" >Logout</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="navbar-toggler ">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </header>
    <!--====== End Header ======-->


</template>



<script>
    import side from '@/views/AuthorizedUserView/AuthorizeUserLayout/SideMenu.vue'


    export default {
        components: {
            side
        },
        name: 'HeaderVue',
        emits: ["openSignUpdmodel", "openLoginPage"],
        data() {
            return {
                companyLogo: '',
                IsOpenRightMenu: false,

            }
        },
        props: ['NavLinks', "isUserLoggedIn"],
        created() {
            this.getCompanyLogo();
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu1");
                    }
                    // footerbottom.classList.add("show-menu1");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu1");
                    }
                    //  footerbottom.classList.remove("show-menu1");
                }

            }, ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let navbar = document.getElementsByClassName("navbar-default");
                    let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                    let sectionFooter = document.getElementsByClassName("section-footer");

                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    this.IsOpenLeftMenu = false

                }

            },
            goToPortalDashboard() {
                this.$emit('goToPortal')
            },
        }
    }
</script>