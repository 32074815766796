<template>


    <div id="confirmation" class="modal fade show" style="padding-right: 17px; display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-confirm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header flex-column">
                    <div class="icon-box">
                        <i class="fas fa-times"></i>
                    </div>
                    <h4 class="modal-title w-100">{{header}}</h4>
                    <button type="button" class="close" aria-hidden="true" @click="$emit('cancel')">×</button>
                </div>
                <div class="modal-body">
                    <p>  {{ question }}</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-primary" @click="$emit('confirm')">{{confirm}}</button>
                    <button type="button" class="btn btn-secondary" @click="$emit('cancel')">{{cancel}}</button>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>

</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        props: ["question", "header", "cancel", "confirm"],
        emits: ["confirm", "cancel"]
    });

</script>

<style>
</style>