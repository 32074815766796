<template>

    <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
            <div class="sidebar-menu">
                <div class="logo logo-desktop px-3">
                    <router-link :to="{ name: 'HomePage'}">
                        <img :src="companyLogo" alt="logo" style="max-height: 30px; max-width:250px; ">
                    </router-link>
                </div>
                <ul>
                    
                    <li v-for="link in sideMenuItems">

                        <router-link :to="{ name: link.routeUrl}" v-if="link.routeUrl!='home'">
                            <i :class="['link.favIcon '+ link.favIcon]"></i>
                            <span>{{link.displayText}}</span>
                        </router-link>
                        <ul class="dropdownlinks" v-if="$filters.IsNull(link.childern,true,null)!= null && link.childern.length>0 ">

            <li v-for="childlink in link.childern" class="child-nav">

                <router-link :to="{ name: childlink.routeUrl}" v-if="$filters.IsNull(childlink,true,null)!=null">
                    <i class="fa fa-chevron-right"></i>
                    <span>{{childlink.displayText}}</span>
                </router-link>
            </li>
        </ul>


                        <router-link :to="{ name: 'HomePage'}" v-if="link.routeUrl=='home'">
                            <i :class="['link.favIcon '+ link.favIcon]"></i>
                            <span>{{link.displayText}}</span>
                        </router-link>
                    </li>
                    <li>
                        <a @click="$emit('logout')" href="javascript:;">
                            <i class="fa fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </a>
                    </li>


                </ul>
            </div>

        </div>
    </nav>

</template>

<script>
    export default {
        name: "SideMenu",
        components: {

        },
        data() {

            return {
                companyLogo: '',
}
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../../assets/Theme/DefaultTheme/images/default-logo.png') : logoUrl;
            },
        },
        created() {
            this.getCompanyLogo();
        },
        props: ["sideMenuItems"],
        emits: ['logout']
    }
</script>
<style>
    .child-nav a {
        color: #c67e24 !important;
        padding: 8px 30px 8px 44px !important;
    }

    li.child-nav {
        background: #eae7e7;
        color: #c67e24;
    }
</style>