<template>
    <div class="modal fade login-register-form show" tabindex="-1" aria-labelledby="login-register-form" style="display: block; padding-left: 0px;" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" @click="this.$emit('cancelForgotModel')"><span><i class="fa fa-times"></i></span></button>
                <div class="modal-body">
                    <div class="tab-content">
                        <Form @submit="sumitlogin" :validation-schema="ForgotPswSchema" v-slot="{ errors }" autocomplete="off">
                            <div class="popup-heading">Forgot Password?</div>
                            <div class="login-form-wrap">
                                <div class="form-group mb-3">
                                    <div class="form-group-field username-field">
                                        <Field type="text" placeholder="Enter Username" name="Username" class="form-control mb-1" data-gtm-form-interact-field-id="0"
                                               v-model="ForgotPasswordform.Username" :class="{ 'is-invalid': errors.Username }" />
                                        <div class="invalid-feedback">{{errors.Username}}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase w-100">
                                Reset Password
                            </button>
                            <div id="return_login" class="login_Register_option"> <a  @click="$emit('openloginpopup')" >Return to Login</a> </div>
                        </Form>
                        <!-- tab-content -->

                    </div><!-- modal-body -->
                </div><!-- modal-content -->
                <div class="modal_login_image_wrapper">
                    <div class="modal_login_image"></div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
    <h1>Varinder</h1>
</template>

<script>
    import { Form, Field } from 'vee-validate';
    export default {

        components: {
            Form, Field
        },
        props: ["ForgotPswSchema","ForgotPasswordform"],
        emits: ["cancelForgotModel", "submitForgotPass","openloginpopup"],
        data() {
            return {
            }
        },
        methods: {
            sumitlogin() {
                this.$emit('submitForgotPass', this.ForgotPasswordform)
            }
        }



    }
</script>