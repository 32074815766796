import axios from "axios";

const baseCommonURL = process.env.VUE_APP_WebApiBaseUrl
const baseURLCommon = `${baseCommonURL}`;

const instance = axios.create({
    baseURL: baseURLCommon

});

export default instance;