<template>
    <SideMenu :sideMenuItems="sideMenuItems" @logout="logout"></SideMenu>
</template>
<script>
    import { createConfirmDialog } from "vuejs-confirm-dialog";
    import SimpleDialog from "../../shared/SimpleDialog.vue";
    import userService from '../../../Services/frontEndService.js'
    export default {
        name: 'SideMenuParent',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.SideMenu = require('@/layout/Themes/DarkTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue').default;
            }
            else if (this.themeName == 'Clean') {
                this.$options.components.SideMenu = require('@/layout/Themes/CleanTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue').default;
            }
            //else if (this.themeName == 'Classic') {

            //    this.$options.components.thankyouComponent = require('@/layout/Themes/ClassicTheme/Views/ThankYou/thankyou.vue').default;
            //}
            //else if (this.themeName == 'Modern') {

            //    this.$options.components.thankyouComponent = require('@/layout/Themes/ModernTheme/Views/ThankYou/thankyou.vue').default;
            //}
            else {
                this.$options.components.SideMenu = require('@/layout/Themes/DefaultTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue').default;
            }
        },
        components: {
            SideMenu: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue');
                }
                //else if (this.themeName == 'Classic') {
                //    import('@/layout/Themes/ClassicTheme/Views/ThankYou/thankyou.vue');
                //}
                //else if (this.themeName == 'Modern') {
                //    import('@/layout/Themes/ModernTheme/Views/ThankYou/thankyou.vue');
                //}
                else {
                    import('@/layout/Themes/DefaultTheme/Views/AuthorizedUserView/AuthorizeUserLayout/sideMenu.vue');
                }
            }
        },


        data() {
         
            return {
                sideMenuItems:[],
               paymentStatus:''
            }
        },
        created() {
            this.GetSideMenuModuleForWeb();
            
        },
        methods: {

          async  GetSideMenuModuleForWeb() {
                let userid = localStorage.getItem('Comp-LoginUserId');
                await userService.GetSideMenuModuleForWeb(userid).then(response => {
                    if (response.data.statusCode == 200) {
                      let data= JSON.parse(localStorage.getItem("comp-Loginuserinfo"))
                        this.paymentStatus = data.paymentStatus;
                        let usertype = data.userType;

                        if (((usertype == "usertype07" || usertype == "usertype03") && this.paymentStatus == "Successful") || (usertype != "usertype07" && usertype != "usertype03")) {

                            this.sideMenuItems = JSON.parse(response.data.result);
                        }
                        else
                        {
                            this.sideMenuItems = [];
                        }
                        
                    }
                })
            },
            logout() {
                const { reveal, onConfirm } = createConfirmDialog(SimpleDialog, {
                    question: `Are you sure you want to Logout?`,
                    header: `Logout`,
                    cancel: `Cancel`,
                    confirm: `Ok`,
                });
                onConfirm(() => {
                    this.isLoading = true;
                    localStorage.removeItem("Comp-LoginUserId");
                    localStorage.removeItem("Comp-Login-access_token");
                    this.$router.push({ name: `HomePage` });
                    setTimeout(() => { this.isLoading = false }, 2500);
                });
                reveal(() => {

                });
            }
        }

    }

</script>