<template>
    <div class="comparisonblock open" id="comparisonblock" v-if="this.$filters.IsNull(AssetComapreArray,true,null)!=null">
        <h6 class="mb-3">Compare listings</h6>
        <div class="row">
            <h1></h1>
            <div class="col-lg-6 mb-3" v-for="(assetItem) in AssetComapreArray">
                <div class="compare-item">
                    <a class="remove-compare remove-icon" @click="RemoveCompareImage(assetItem)">
                        <i class="houzez-icon icon-remove-circle"></i>
                    </a>
                    <img :src="assetItem.ImagePaths[0].ImagePath" :alt="assetItem.name" class="img-a img-fluid" />
                </div>
            </div>
            <div class="col-lg-12 mb-3">
                <a class="btn btn-primary" @click="AssetCompare(AssetComapreArray)">Compare</a>
            </div>
            <div class="col-lg-12 mb-3">
                <a class="btn btn-secondary" @click="CloseComparePopup">Close</a>
            </div>
        </div>
        <button class="compare-property-label active" @click="CollaspeComaprePopup">
            <span class="compare-count compare-label">{{AssetComapreArray.length}}</span>
            <i class="houzez-icon icon-move-left-right"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: "AssetComaprePopup",
        emits: ["CloseComparePopup", "SwitchToAssetCompareDetail","RemoveCompareImage"],
        props:["AssetComapreArray"],
        components: {
        },

        created() {
        },
        
        data() {
            
            return {
            }
        },
       
        methods: {
            AssetCompare(assetComapreArray) {
                this.$emit("SwitchToAssetCompareDetail", assetComapreArray);
            },
            CloseComparePopup() {
                this.$emit("CloseComparePopup");
            },
            RemoveCompareImage(assetItem)
            {
                this.$emit("RemoveCompareImage",assetItem);
            },

            CollaspeComaprePopup() {
                let compareElement = document.getElementById('comparisonblock');
                compareElement.classList.toggle('open')
               
            },
            
        },
    }
</script>
