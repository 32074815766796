<template>
    <AsseComparePopupComponent @SwitchToAssetCompareDetail="SwitchToAssetCompareDetail"
                               @ShowAssetComparePopup="ShowAssetComparePopup"
                               @CloseComparePopup="CloseComparePopup" :AssetComapreArray="AssetComapreArray"
                               @RemoveCompareImage="RemoveCompareImage"
                               :AssetCompareDetailarray="AssetCompareDetailarray">
    </AsseComparePopupComponent>
    <Loading v-model:active="isLoading">
    </Loading>
</template>
<script>
   
    import Loading from 'vue-loading-overlay';
    export default {
        name: "AssetComparePopup",
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.AsseComparePopupComponent = require('@/layout/Themes/DarkTheme/Views/ManageAsset/assetComparePopup.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.AsseComparePopupComponent = require('@/layout/Themes/CleanTheme/Views/ManageAsset/assetComparePopup.vue').default;
            }
            else {
                this.$options.components.AsseComparePopupComponent = require('@/layout/Themes/DefaultTheme/Views/ManageAsset/assetComparePopup.vue').default;
            }
        },
        components: {
            AsseComparePopupComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/ManageAsset/assetComparePopup.vue');
                }
                else if (this.themeName == 'Clean')
                {
                    import('@/layout/Themes/CleanTheme/Views/ManageAsset/assetComparePopup.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/ManageAsset/assetComparePopup.vue');
                }
            },
            Loading,
        },
        props: ['AssetComapreArray'],
        data() {

            let themeName = localStorage.getItem('theme');
            return {
                IsComparePopUpOpen: false,
                AssetCompareDetailarray: null
            }
        },
        created() {
        },
        updated() {

        },
        methods: {
            CloseComparePopup() {
            this.$parent.CloseComparePopup();
            },
            RemoveCompareImage(event) {
                this.$parent.ShowAssetComparePopup(event);
            },
            SwitchToAssetCompareDetail(event) {
                let compareElement = document.getElementById('comparisonblock');
                compareElement.classList.toggle('open')
                this.$router.push({ name: `assetcomparedetails` })
            }
        }
    }
</script>
