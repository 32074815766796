<template>
    <newsLetterComponent :schema="schema" :emailValuecomp="emailValuecomp" @submitNewsLetter="submitNewsLetter"
                         ref="newsLetterdata">
    </newsLetterComponent>
    <Loading v-model:active="isLoading">
    </Loading>
</template>
<script>
    import Service from '@/Services/frontEndService.js';
    import * as Yup from 'yup';
    import axios from 'axios';
    export default {
        name: 'newsletter',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.newsLetterComponent = require('@/layout/Themes/DarkTheme/Views/NewsLetter/newsLetter.vue').default;
            }
            else if (this.themeName == 'Clean') {

                this.$options.components.newsLetterComponent = require('@/layout/Themes/CleanTheme/Views/NewsLetter/newsLetter.vue').default;
            }
            else if (this.themeName == 'Classic') {

                this.$options.components.newsLetterComponent = require('@/layout/Themes/ClassicTheme/Views/NewsLetter/newsLetter.vue').default;
            }
            else if (this.themeName == 'Modern') {

                this.$options.components.newsLetterComponent = require('@/layout/Themes/ModernTheme/Views/NewsLetter/newsLetter.vue').default;
            }
            else {
                this.$options.components.newsLetterComponent = require('@/layout/Themes/DefaultTheme/Views/NewsLetter/newsLetter.vue').default;
            }
        },
        components: {
            newsLetterComponent: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Views/NewsLetter/newsLetter.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Views/NewsLetter/newsLetter.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Views/NewsLetter/newsLetter.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Views/NewsLetter/newsLetter.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Views/NewsLetter/newsLetter.vue');
                }
            }
        },
        data() {
            const companyId = localStorage.getItem('companyId')
            const schema = Yup.object().shape({
                emailname: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            let themeName = localStorage.getItem('theme');
            const emailValuecomp = {
                EmailValue: ""
            }
            return {
                schema,
                companyId,
                isLoading: false,
                themeName,
                emailValuecomp,
                RefreshToken: '',
                AccessToken: '',
                LocationId: '',
                Email: ''
            }
        },
        methods: {
            async submitNewsLetter(emailValuecomp) {

                this.isLoading = true;
                var manageSubscribersModel = {
                    email: emailValuecomp,
                    companyId: this.companyId,
                    host: "https://" + window.location.host
                }
                await Service.AddSubscriber(manageSubscribersModel).then(Response => {
                    if (Response != null && Response.data.statusCode == 200 || Response.data.statusCode == 201) {
                        this.isLoading = false;
                        //this.$refs.newsLetterdata.emailValuecomp = ''
                        Object.assign(this.$data, this.$options.data());
                        this.$refs.newsLetterdata.showForm = true;
                        if (Response.data.statusCode == 200) {
                            if (this.companyId.toUpperCase() == "E42A359A-D517-483C-8D7D-5353CA886B41") {
                                this.Email = emailValuecomp,
                                    this.GetRefreshToken();

                            }

                            this.$router.push({
                                name: "thankyou",
                                state: {
                                    routeMessage: {
                                        IsSuccess: true,
                                        displayMessage: 'You have successfully joined our subscriber list'
                                    }
                                }
                            }
                            )
                        }
                        else {
                            this.$router.push({
                                name: "thankyou",
                                state: {
                                    routeMessage: {
                                        Email: emailValuecomp,
                                        displayMessage: 'You have already subscribed.',
                                        IsSuccess: false,
                                    }
                                }
                            });
                        }
                    }
                    else {
                        this.isLoading = false;
                        this.error('Bad req')
                    }

                })
            },

            async GetAccessToken() {
                const encodedParams = new URLSearchParams();
                encodedParams.set('client_id', '6516bc5ba2ae7d3622b39ff6-ln4k1782');
                encodedParams.set('client_secret', 'e4cfa4bd-6c5e-4062-bfca-f92a9a3670c2');
                encodedParams.set('grant_type', 'refresh_token');
                // encodedParams.set('code', '2f3f4aafa9a9e35142df55c54cfafa7e22d49923');
                encodedParams.set('refresh_token', this.RefreshToken);
                //encodedParams.set('user_type', '');
                //encodedParams.set('redirect_uri', '');

                const options = {
                    method: 'POST',
                    url: 'https://services.leadconnectorhq.com/oauth/token',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json'
                    },
                    data: encodedParams,
                };

                try {
                    const { data } = await axios.request(options);
                    console.log('data', data)
                    console.log('data.refresh_token', data.refresh_token)
                    console.log('data.access_token', data.access_token)
                    this.RefreshToken = data.refresh_token
                    this.AccessToken = data.access_token
                    this.LocationId = data.locationId
                    this.UpdateTokens()
                } catch (error) {
                    console.log('catch', error)
                }

            },

            async GetRefreshToken() {
                await Service.GetRefreshToken(this.companyId).then(Response => {
                    if (Response.status == 200) {
                        this.RefreshToken = Response.data
                        this.GetAccessToken();
                    }
                });
            },

            async UpdateTokens() {
                var model = {
                    RefreshToken: this.RefreshToken,
                    AccessToken: this.AccessToken,
                    CompanyId: this.companyId
                }
                await Service.UpdateTokens(model).then(Response => {
                    if (Response.status == 200) {
                        this.CreateContact()
                    }
                });
            },

            async CreateContact() {


                axios.post(`https://services.leadconnectorhq.com/contacts/`, {
                    email: this.Email,
                    locationId: this.LocationId,
                    source: "website",
                    customFields: [
                        {
                            "id": "ns9qQKCtk1C6DtvAv2j5",
                            "key": "contact.sub_source",
                            "field_value": "Newsletter"
                        },
                        {
                            "id": "eLSrsHkS1nB0G4G0prBt",
                            "Key": "contact.message",
                            "field_value": "" + " Submission URL: " + location.href
                        }
                    ],
                },
                    {
                        headers: {
                            Authorization: `Bearer ${this.AccessToken}`,
                            Version: '2021-07-28',
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        }
                    }).then(res => {

                        this.isLoading = false;
                        console.log('oper Res', res)
                    }).catch(error => {
                        this.isLoading = false;

                    });


            }
        }
    }
</script>