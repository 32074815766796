<template>
    <div class="social mb-3" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
        <a :href="[item.Url]" class="icon" v-for="item in socialmedialinkList" target="_blank">
            <i :class="[item.IconUsed]"></i>
        </a>
    </div>
</template>

<script>
    export default {
        props: ["companyName", "schema", "socialmedialinkList"]
    }
</script>
