<template>

    <Header :NavLinks="NavLinks" @openSignUpdmodel="openSignUpdmodel"
            @goToPortal="goToPortal"
            @openLoginPage="openLoginPage"
            v-if="ShowHeaderFooter"
            @logout="logout" :isUserLoggedIn="isUserLoggedIn" ref="headerElement" :name="name" :ProfilePic="ProfilePic"></Header>


    <div class="page-body-wrapper" @click="CloseHeader">
        <div class="page-body">
            <router-view :AssetComapreArray="AssetComapreArray" />
        </div>
        <div class="tab-pane fade login-form-tab show active" id="login-form-tab" role="tabpanel">
            <div id="hz-login-messages" class="hz-social-messages"></div>
        </div>
    </div>
    <Footer id="footer-bottom"
            v-if="ShowHeaderFooter"
            :footerNavLinks1="footerNavLinks1" :footerNavLinks2="footerNavLinks2" :footerNavLinks3="footerNavLinks3" :serviceLinks="serviceLinks" :FooterLogoDescription="FooterLogoDescription" :OurAddress="OurAddress" :OurEmail="OurEmail" :OurPhone="OurPhone" :Copyrights="Copyrights" :FooterworkusNavLinks="FooterworkusNavLinks">
    </Footer>
    <!--<signUpComponent v-if="isopenSignUpModal" @cancelClicked="closeSignUpdmodel"></signUpComponent>-->
    <!--<loginComponent v-if="loginmodelopen" :AfterLoginRoute="AfterLoginRoute" @cancelmodel="CloseLoginModal"></loginComponent>-->
    <AssetComparePopupComponent v-if="isComparePopUpOpen" :AssetComapreArray="AssetComapreArray" @ShowAssetComparePopup="ShowAssetComparePopup" @CloseComparePopup="CloseComparePopup" @RemoveCompareImage="RemoveCompareImage"></AssetComparePopupComponent>
    <div class="modal-backdrop fade" id="isvisbleModalFade"></div>
    <subscribeNewsletter v-if="showsubscribenewsletter || isPopUpShown =='true'" @closenewslettermodel="closenewslettermodel" @permanentclose="permanentclose"></subscribeNewsletter>
    <Loading v-model:active="isLoading"></Loading>

    <a href="#" :class="{'back-to-top d-flex align-items-center justify-content-center':true,'active':isscrolled}"><i class="houzez-icon icon-arrow-up-1 text-white"></i></a>
</template>

<script>
    import { createConfirmDialog } from "vuejs-confirm-dialog";
    import SimpleDialog from "../views/shared/SimpleDialog.vue";
    import AssetComparePopupComponent from '@/views/ManageAsset/assetComparePopup.vue';
    import AuthUserServices from '../Services/AuthUserServices.js';
    import UserService from '@/Services/frontEndService.js';


  //  D: \ajay\BEENEE_PROJECT\storeFrant\src\layout\Themes\DefaultTheme\Views\AuthorizedUserView\MyProfile\myProfile.vue
    export default {
        name: 'layoutName',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'Dark') {
                this.$options.components.Header = require('@/layout/Themes/DarkTheme/Components/headervue/Header.vue').default;
                this.$options.components.Footer = require('@/layout/Themes/DarkTheme/Components/footervue/Footer.vue').default;
                require('@/assets/Theme/DarkTheme/CSS/style.css')
                require('@/assets/Theme/DarkTheme/CSS/animate.min.css')
                require('@/assets/Theme/DarkTheme/CSS/flaticon.css')
                document.body.classList.add('dark-version');

            }
            else if (this.themeName == 'Clean') {
                this.$options.components.Header = require('@/layout/Themes/CleanTheme/Components/headervue/Header.vue').default;
                this.$options.components.Footer = require('@/layout/Themes/CleanTheme/Components/footervue/Footer.vue').default;
                require('@/assets/Theme/CleanTheme/CSS/style.css')
                require('@/assets/Theme/CleanTheme/JS/custom.js')
            }
            else if (this.themeName == 'Modern') {
                this.$options.components.Header = require('@/layout/Themes/ModernTheme/Components/headervue/Header.vue').default;
                this.$options.components.Footer = require('@/layout/Themes/ModernTheme/Components/footervue/Footer.vue').default;
                require('@/assets/Theme/ModernTheme/CSS/style.css')
                require('@/assets/Theme/ModernTheme/CSS/font-icons.css')
            }
            else if (this.themeName == 'Classic') {
                this.$options.components.Header = require('@/layout/Themes/ClassicTheme/Components/headervue/Header.vue').default;
                this.$options.components.Footer = require('@/layout/Themes/ClassicTheme/Components/footervue/Footer.vue').default;
                require('@/assets/Theme/ClassicTheme/CSS/style.css')
                require('@/assets/Theme/ClassicTheme/CSS/animate.min.css')
            }
            else {
                this.$options.components.Header = require('@/layout/Themes/DefaultTheme/Components/headervue/Header.vue').default;
                this.$options.components.Footer = require('@/layout/Themes/DefaultTheme/Components/footervue/Footer.vue').default;

                require('@/assets/Theme/DefaultTheme/CSS/style.css')

            }
            this.$options.components.loginComponent = require('@/views/LoginCompany/logincompany.vue').default;
            this.$options.components.signUpComponent = require('@/views/SignUp/signUp.vue').default;
            this.$options.components.subscribeNewsletter = require('@/views/SubscribeNewsletter/subscribeNewsletter.vue').default;
            //.$options.components.myProfile = require('@/layout/Themes/DefaultTheme/Views/AuthorizedUserView/MyProfile/myProfile.vue').default;

        },
        components: {
            myProfile: () => {
                import ('@/layout/Themes/DefaultTheme/Views/AuthorizedUserView/MyProfile/myProfile.vue')
            },
            Header: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Components/headervue/Header.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Components/headervue/Header.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Components/headervue/Header.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Components/headervue/Header.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Components/headervue/Header.vue');
                }

            },
            Footer: () => {
                if (this.themeName == 'Dark') {
                    import('@/layout/Themes/DarkTheme/Components/footervue/Footer.vue');
                }
                else if (this.themeName == 'Clean') {
                    import('@/layout/Themes/CleanTheme/Components/footervue/Footer.vue');
                }
                else if (this.themeName == 'Modern') {
                    import('@/layout/Themes/ModernTheme/Components/footervue/Footer.vue');
                }
                else if (this.themeName == 'Classic') {
                    import('@/layout/Themes/ClassicTheme/Components/footervue/Footer.vue');
                }
                else {
                    import('@/layout/Themes/DefaultTheme/Components/footervue/Footer.vue');
                }
            },
            signUpComponent: () => {
                import('@/views/SignUp/signUp.vue');
            },
            loginComponent: () => {
                import('@/views/LoginCompany/logincompany.vue');
            },
            subscribeNewsletter: () => {
                import('@/views/SubscribeNewsletter/subscribeNewsletter.vue');
            },

            AssetComparePopupComponent



        },
        data() {
            let themeName = localStorage.getItem('theme');
            let NavLinks; let footerNavLinks1; let footerNavLinks2; let footerNavLinks3; let FooterworkusNavLinks;
            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                NavLinks = JSON.parse(this.$router.options.HeaderNavlinks);
            }
            else {
                NavLinks = JSON.parse(localStorage.getItem("HeaderNavlinks"));
            }

            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks1 = JSON.parse(this.$router.options.FooterNavlinks1);
            }
            else {
                footerNavLinks1 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }

            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks2 = JSON.parse(this.$router.options.FooterNavlinks2);
            }
            else {
                footerNavLinks2 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }

            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks3 = JSON.parse(this.$router.options.FooterNavlinks3);
            }
            else {
                footerNavLinks3 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }


            if (this.$router.options.FooterworkusNavLinks != null && this.$router.options.FooterworkusNavLinks != "") {
                FooterworkusNavLinks = JSON.parse(this.$router.options.FooterworkusNavLinks[0]);
            }
            else {
                FooterworkusNavLinks = JSON.parse(localStorage.getItem("FooterworkusNavLinks"));
            }
            console.log('get nav links', NavLinks)

            const FooterLogoDescription = 'Our property management software streamlines the entire occupancy process to save you time and money.';
            const CurrentYear = new Date().getFullYear();
            const Copyrights = `Copyright &copy;  ${CurrentYear} - ${localStorage.getItem('companyName')}. All Rights Reserved.`
            let isPopUpShown = localStorage.getItem('isSubscribePopupShown');
            return {
                themeName,
                NavLinks,
                footerNavLinks1,
                footerNavLinks2,
                footerNavLinks3,
                FooterworkusNavLinks,
                FooterLogoDescription,
                OurAddress: '',
                OurEmail: '',
                OurPhone: '',
                Copyrights,
                isopenSignUpModal: false,
                loginmodelopen: false,
                isUserLoggedIn: false,
                isLoading: true,
                isCloseComparePopup: false,
                AfterLoginRoute: 'Home',
                isComparePopUpOpen: false,
                comparePopupiewDetail: null,
                AssetComapreArray: [],
                isscrolled: false,
                scrollPosition: 0,
                showsubscribenewsletter: false,
                timeinterval: 15000,
                ShowHeaderFooter: true,
                isPopUpShown,
                name,
                ProfilePic: null,

            }
        },

        created() {
            this.isLoading = true;
            this.GetAddressDetailOfCompany();
            this.isUserLoggedIn = (localStorage.getItem('access_token')) ? true : false;
            if (this.isUserLoggedIn == true) {
                localStorage.setItem('userlogin', true);
            }
            else {
                localStorage.setItem('userlogin', false);
            }
            setTimeout(() => { this.isLoading = false }, 1000);
            debugger;
            if (this.isPopUpShown == false) {
                localStorage.setItem('isSubscribePopupShown', 'true');

            }
            let commpanyUrl = localStorage.getItem('companyURL');
            let found = this.$route.fullPath.includes(commpanyUrl + '/dashboard');
            this.name = JSON.parse(localStorage.getItem('userinfo'));
            if (this.$filters.IsNull(this.name, true, null) != null) {
                this.ProfilePic = (this.$filters.IsNull(this.name.profilePic, true, null) == null) ? require('../assets/images/default.png') : this.name.profilePic;
            }
            this.ShowHeaderFooter = (found) ? false : true;
            this.GetDynamicContent();
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
            let companyID = localStorage.getItem('companyId')
            let companyName = localStorage.getItem('companyName')
            if (companyID == 'e42a359a-d517-483c-8d7d-5353ca886b41' || companyID == 'E42A359A-D517-483C-8D7D-5353CA886B41') {
                this.setTimerForSubsribePopUp();
            }

        },
        //watch: {
        //    $route(to, from) {
        //        let commpanyUrl = localStorage.getItem('companyURL');
        //        let found = to.fullPath.includes(commpanyUrl +'/dashboard');
        //        this.ShowHeaderFooter = (found)? false:true;
               
        //    }
        //},
        updated() {
            var CurrnetUserLoggedIn = (localStorage.getItem('access_token')) ? true : false;
            if (this.isUserLoggedIn != CurrnetUserLoggedIn) {
                this.isUserLoggedIn = CurrnetUserLoggedIn;
                this.name = JSON.parse(localStorage.getItem('userinfo'));
                this.ProfilePic = (this.$filters.IsNull(this.name.profilePic, true, null) == null) ? require('../assets/images/default.png') : this.name.profilePic;
            }
            if (this.isUserLoggedIn == true) {
                localStorage.setItem('userlogin', true);
            }
            else {
                localStorage.setItem('userlogin', false);
            }
            this.isPopUpShown = localStorage.getItem('isSubscribePopupShown');

        },
        methods: {
            goToPortal() {
                let UserName = JSON.parse(localStorage.getItem('userinfo')).userName
                let encryptedUserName = this.$helpers.EncryptUserName(UserName, this);
                window.location.assign(`${this.$custConfig.adminPortalUrl}loginCompanyUser/${encryptedUserName}/${location.host}/${localStorage.getItem('companyURL')}`)
            },
            setTimerForSubsribePopUp() {
                //let cookie = document.cookie;
                if (!this.isUserLoggedIn) {
                    setTimeout(() => {
                        this.showsubscribenewsletter = true;
                        this.$helpers.enbalePopUpCss();
                    }, this.timeinterval)

                }

            },
            closenewslettermodel() {
                this.showsubscribenewsletter = false;
                this.timeinterval = 300000
                this.setTimerForSubsribePopUp();
                this.$helpers.disablePopUpCss();
                localStorage.setItem('isSubscribePopupShown', 'false');
            },
            permanentclose() {
                this.showsubscribenewsletter = false;
                this.$helpers.disablePopUpCss();
            },
            updateScroll() {
                this.scrollPosition = window.scrollY;
                // alert(this.scrollPosition)
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                    setTimeout(() => {
                        if (this.scrollPosition != 0)
                            $("#headersticky").addClass("header-sticky")
                    }, 1000);

                }
                else {
                    this.isscrolled = false;
                    $("#headersticky").removeClass("header-sticky");
                }
            },
            async logout() {
                const IPAddress =  await UserService.GetIPAddress();
                const { reveal, onConfirm } = createConfirmDialog(SimpleDialog, {
                    question: `Are you sure you want to Logout?`,
                    header: `Logout`,
                    cancel: `Cancel`,
                    confirm: `Ok`,
                });
                onConfirm(() => {
                    this.isLoading = true;
                    //UserService.LogoutHistory(localStorage.getItem('userId'),IPAddress).then(res => {
                    //  })
                    localStorage.removeItem("userId");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem('IsShip')
                    localStorage.removeItem('SelectedProductId');
                    this.$router.push({ name: `HomePage` });
                    //this.$router.go(0);
                    this.isUserLoggedIn = false;
                    setTimeout(() => { this.isLoading = false }, 2500);
                });
                reveal(() => {

                });
            },
            GetAddressDetailOfCompany() {
                try {
                    let companyAddress = JSON.parse(this.$filters.IsNull(localStorage.getItem('companyAddress'), true, null));
                    this.OurEmail = this.$filters.IsNull(companyAddress.email, true, ' - ');
                    this.OurPhone = this.$filters.IsNull(companyAddress.phone, true, ' - ');
                    this.OurAddress = this.$filters.IsNull(companyAddress.Address1, ' ') + ' ' + this.$filters.IsNull(companyAddress.Address2, ' ') + ', ' + this.$filters.IsNull(companyAddress.city, ' ') + ', ' + this.$filters.IsNull(companyAddress.state, ' ') + ', ' + this.$filters.IsNull(companyAddress.zip, ' ') + ', ' + this.$filters.IsNull(companyAddress.country, ' ');
                }
                catch (error) {
                    this.OurAddress = '';
                    this.OurEmail = '';
                    this.OurPhone = '';
                }
            },
            //openSignUpdmodel() {
            //    this.isLoading = true;
            //    this.isopenSignUpModal = true;
            //    this.$helpers.enbalePopUpCss();
            //    setTimeout(() => {
            //        this.isLoading = false;
            //    }, 1000);
            //},
            //closeSignUpdmodel() {
            //    this.isopenSignUpModal = false;
            //    this.$helpers.disablePopUpCss();
            //},
            OpenLoginModal(AfterLoginRoute = 'Home') {
                //this.AfterLoginRoute = AfterLoginRoute;
                //this.isLoading = true;
                //this.loginmodelopen = true;
                //this.$helpers.enbalePopUpCss();
                //setTimeout(() => { this.isLoading = false }, 1000);
            },
            //CloseLoginModal() {
            //    this.loginmodelopen = false;
            //    this.$helpers.disablePopUpCss();
            //},
            openLoginPage() {
                this.$router.push({ name: `Login` });
            },
            switchToAssetComapredetail(id) {
                this.$router.push({ path: `assetCompareDetails/${id}` });
            },
            CloseComparePopup() {
                this.AssetComapreArray = [];
                this.isComparePopUpOpen = false;
                this.$helpers.disablePopUpCss();
            },
            ShowAssetComparePopup(newassetValue) {
                let foundIndex = this.AssetComapreArray.findIndex(item => {
                    return item.id == newassetValue.id
                })

                //  Asset found
                if (foundIndex != -1) {
                    this.AssetComapreArray.splice(foundIndex, 1);
                }
                else {
                    if (this.AssetComapreArray.length < 4) {
                        this.AssetComapreArray.push(newassetValue);
                    }
                    else {
                        this.error("You can'not add more than 4 item");
                    }
                }
                this.$helpers.enbaleComparePopUpCss();
                this.isComparePopUpOpen = true;
            },
            CloseHeader() {
                try {
                    this.$refs.headerElement.CloseLeftMenu();
                }
                catch (error) {

                }
            },
            GetDynamicContent() {
                AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    this.FooterLogoDescription = res.data.footerContent
                })
            },
        },
    }
</script>
