<template>
    <div :class="{'modal right fade show newsletter-subscription':true, 'in':addclass}" tabindex="-1" aria-labelledby="login-register-form" style="display: block; padding-left: 0px;" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" @click="$emit('cancel')"><span><i class="fa fa-times"></i></span></button>
                <div class="modal-body">
                    <div class="tab-content">
                        <div class="modal_login_image_wrapper">
                            <div class="modal_login_image"></div>
                        </div>
                        <Form @submit="submitdata" :validation-schema="schema" v-slot="{ errors }" autocomplete="off">
                            <div><h3>Subscribe to Our Newsletter</h3></div>
                            <div>
                                <p>
                                    For a chance to win one free night at this exceptional property.
                                    Stay up-to-date on all the exciting new homes and locations around the globe with {{companyname}}
                                </p>
                            </div>
                            <div class="login-form-wrap">
                                <div class="form-group">
                                    <div class="form-group-field username-field">
                                        <Field type="text" placeholder="Enter Name*" name="name" class="form-control mb-1" data-gtm-form-interact-field-id="0"
                                               v-model="formdata.name" :class="{'is-invalid': errors.name }" />
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group-field password-field">
                                        <Field type="text" placeholder="Enter Email*" name="email" v-model="formdata.email"
                                               data-gtm-form-interact-field-id="1"
                                               class="form-control mb-1"
                                               :class="{ 'is-invalid': errors.email }" />
                                        <div class="invalid-feedback">{{errors.email}}</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group-field phone-field">
                                        <Field v-slot="{field }"
                                               v-model="formdata.phone"
                                               name="phone"
                                               value="value">

                                            <input-mask name="phone"
                                                        v-model="formdata.phone"
                                                        :model-value="field.value"
                                                        mask="(999) 999-9999"
                                                        placeholder="Enter Phone*"
                                                        :class="{'form-control mb-1' : true , 'is-invalid': errors.phone} "
                                                        type="text"></input-mask>
                                            <div class="invalid-feedback">{{errors.phone}}</div>
                                        </Field>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group-field phone-field">
                                        <div id="recaptcha-main" class="g-recaptcha" :data-sitekey="siteKey">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button id="houzez-login-btn" type="submit" class="btn btn-primary btn-full-width">
                                Subscribe
                            </button>

                        </Form>

                    </div>
                </div>                
            </div>
        </div>
    </div>


</template>

<script>

    import { Form, Field } from 'vee-validate';
    import InputMask from '@/views/shared/inputmask/inputmasktemplate.vue'

    export default {
        components: { Form, Field, InputMask },
        props: ["schema", "formdata", "showsubscribenewsletter"],
        emits: ["submitdata", "cancel"],
        methods: {
            submitdata() {
                this.$emit('submitdata', this.formdata)
            }
        },
        data() {

            return {
                companyname:localStorage.getItem('companyName'),
                addclass: false,
                siteKey: '6LfPmGQnAAAAAKF_W3hIf1iiQ33tbLRw4qmKYdBO',
            }
        },
        created() {
            setTimeout(() => { this.addclass = true; this.$helpers.enbalePopUpCss(); }, 1000);
            setTimeout(() => {
                grecaptcha.render('recaptcha-main'); // 'recaptcha-main' is the id that was assigned to the widget
            }, 1000);
        }

    }
</script>