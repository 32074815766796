import api from './apiSetting';

class AuthUserServices {

    async GetDynamicContentForComapny(companyId) {
        return await api.get(`ManageCompanyWebPage/GetDynamicContentForComapny/${companyId}`);
    }
    async GetAmbassadorClientList() {
        let userId = localStorage.getItem('userId');
        return await api.get(`ManageTestimonial/GetAmbassadorClientList/${userId}`);

    }
    async GetClientMsgHistory(SearchModel) {
        return api.post('ManageTestimonial/GetClientMsgHistory', SearchModel);
    }
    async SendMultipleSMS(sendSmsModel) {
        return api.post('VacationSwapMarketplace/SendMultipleSMS', sendSmsModel);
    }
    async ClientTextMessageHistory(ClienthistoryModal) {
        return api.post('ManageTestimonial/AddAmbassadorClientTextMessageHistory', JSON.stringify(ClienthistoryModal));
    }
    async GetAssetDetailsForBooking(assetId) {
        return await api.get(`ManageAsset/GetAssetDetailsForBookingProperty/${assetId}`);
    }
    async AddBookProperty(BookPropertiesModel) {
        return await api.post(`ManageAsset/BookProperties`, BookPropertiesModel);
    }
}
export default new AuthUserServices()