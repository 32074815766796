<template>
    <header id="myDIV" :class="(isscrolled)?'header-one three header--sticky sticky':'header-one three header--sticky'">
        <div class="container">

            <div class="main-nav navbar slideout-menu slideout-menu-right open" id="nav-mobile1" v-if="IsOpenRightMenu">
                <ul id="mobile-main-nav" class="navbar-nav mobile-navbar-nav" v-if="!isUserLoggedIn">
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page ">
                        <router-link class="nav-link" :to="{name:'Login'}" @click="OpenRightMenu">
                            <i class="houzez-icon icon-lock-5 ml-1"></i>Login
                        </router-link>
                    </li>
                    <!--<li class="nav-item menu-item menu-item-type-post_type menu-item-object-page ">
                        <router-link class="nav-link" :to="{name:'SignupPage'}" @click="OpenRightMenu">
                            <i class="houzez-icon icon-single-neutral-circle me-1"></i>Sign Up
                        </router-link>
                    </li>-->
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page ">
                        <router-link class="nav-link" :to="{name:'SignupPage'}" @click="OpenRightMenu">
                            <i class="houzez-icon icon-single-neutral-circle ml-1"></i>
                            Register
                        </router-link>
                    </li>
                </ul>
                <ul id="nav" v-if="isUserLoggedIn">
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page">
                        <a class="nav-link rm-usrnme" href="javascript:;">{{name.firstName}} {{name.lastName}}</a>
                    </li>
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page">
                        <a class="nav-link" href="javascript:;" @click="goToPortalDashboard">Go To Portal</a>
                    </li>
                    <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page" style="display:block">
                        <a class="nav-link" @click="logoutfrommenu">Logout</a>
                    </li>
                    <!--<side class="mobileMenu"></side>-->
                </ul>

            </div>

            <div class="row align-items-center">
                <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-between">
                    <button class="navbar-toggler" @click="ToggleLeftMenu">
                        <i class="fas fa-bars"></i>
                    </button>
                    <div class="logo-area">
                        <router-link :to="{ name: 'HomePage'}">
                            <img :src="ChangeCompanyLogo()" alt="logo">
                        </router-link>
                    </div>
                    <button class="btn toggle-button-right" @click="OpenRightMenu">
                        <img v-if="isUserLoggedIn" :src="ProfilePic" alt="img">
                        <i  v-else class="fas fa-user ml-1"></i>
                    </button>
                </div>



                <div class="col-xl-9 col-lg-8 col-md-9 col-sm-6 slideout-menu-left open" id="login-register-form" v-if="!isUserLoggedIn">

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <nav class="main-nav d-xl-block">
                            <ul id="nav">
                                <li class="" v-for="link in NavLinks">
                                    <router-link :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu">{{link.displayText}}</router-link>
                                </li>

                                <li><div class="frx-button-area text-anim"><a class="frx-btn btn-main-3" @click="$emit('openLoginPage')" v-if="!isUserLoggedIn">  Login/Signup </a></div></li>
                            </ul>

                        </nav>
                    </div>
                </div>

                <div class="col-xl-8 col-lg-8 col-md-9 col-sm-6 slideout-menu-left open" id="login-register-form" v-if="isUserLoggedIn">

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <nav class="main-nav d-xl-block ">
                            <ul id="nav">
                                <li class="menu-item dropdown" v-for="(link, index)  in NavLinks" :key="index">
                                    <router-link :class="[JSON.parse(link.childMenu).length >0 ? 'dropdown-toggle' : '','nav-link']" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                    <ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                        <li v-for="childLink in JSON.parse(link.childMenu)">
                                            <!--<a class="dropdown-item" :href="childLink.displayText">{childLink.displayText}}</a>-->
                                            <router-link class="nav-link dropdown-item" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                        </li>
                                    </ul>

                                </li>
                                <li class="nav-item dropdown pr-link show">
                                    <router class="nav-link dropdown-toggle useraccount" data-toggle="dropdown" href="#/dashboard" aria-expanded="true">
                                        <img :src="ProfilePic" alt="img">
                                        <span class="rm-usrnme">{{name.firstName}} {{name.lastName}}</span>
                                        </router>
                                        <ul class="dropdown-menu show">
                                            <li class="nav-item menu-item menu-item-type-post_type menu-item-object-page">
                                                <a class="nav-link" href="javascript:;" @click="$emit('goToPortal')">Go To Portal</a>
                                            </li>
                                            <li>
                                                <a class="login-link" routerlinkactive="active" @click="this.$emit('logout')" ng-reflect-router-link-active="active">Logout</a>
                                            </li>
                                        </ul>
                                </li>
                            </ul>

                        </nav>
                    </div>



                </div>

            </div>
        </div>

    </header>

</template>

<script>
    import side from '@/views/AuthorizedUserView/AuthorizeUserLayout/SideMenu.vue'
    export default {
        name: 'HeaderVue',
        components: {
            side
        },
        props: ['NavLinks', 'isUserLoggedIn', 'name','ProfilePic'],
        emits: ["openLoginPage"],
        data() {
            return {
                companyLogo: '',
                isscrolled: false,
                IsOpenLeftMenu: false,
                IsOpenRightMenu: false,
                companyname: localStorage.getItem('companyName'),

            }
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        created() {
            this.getCompanyLogo();

        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            },
            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }
            },
            ChangeCompanyLogo() {
                let companyLogo = localStorage.getItem('companyLogo');
                let companySecondaryLogo = localStorage.getItem('companySecondaryLogo');
                var logo = this.isscrolled ? companySecondaryLogo : companyLogo
                return logo

            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let pagewrapper = document.getElementsByClassName("page-wrapper");
                let darkversion = document.getElementsByClassName("dark-version");
                let sectioncomparison = document.getElementsByClassName("comparisonblock");
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.add("menusticky");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.add("show-menu1");
                    }
                } else {
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.remove("menusticky");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.remove("show-menu1");
                    }

                }

            },
            ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let pagewrapper = document.getElementsByClassName("page-wrapper");
                let darkversion = document.getElementsByClassName("dark-version");
                //let navbar = document.getElementsByClassName("page-wrapper");
                //let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                //let sectionFooter = document.getElementsByClassName("section-footer");
                //let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.add("menusticky");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.remove("menusticky");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let pagewrapper = document.getElementsByClassName("page-wrapper");
                    let darkversion = document.getElementsByClassName("dark-version");
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.remove("menusticky");
                    }
                    this.IsOpenLeftMenu = false

                }
                if (this.IsOpenRightMenu) {
                    let pagewrapper = document.getElementsByClassName("page-wrapper");
                    let darkversion = document.getElementsByClassName("dark-version");
                    for (let i = 0; i < pagewrapper.length; i++) {
                        pagewrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < darkversion.length; i++) {
                        darkversion[i].classList.remove("menusticky");
                    }
                    this.IsOpenRightMenu = false

                }
            },
            goToPortalDashboard() {
                this.OpenRightMenu();
                this.$emit('goToPortal')
            },
            logoutfrommenu() {
                this.OpenRightMenu();
                this.$emit('logout');
            }
        }
    }
</script>