<template>
    <header class="header-area">
        <div id="header-sticky" :class="(isscrolled)?'menu-area sticky-menu':'menu-area'">
            <div class="container">
                <div class="second-menu">
                    <div class="row align-items-center">
                        <div class="col-xl-2 col-lg-3 col-md-12 flexMobile">
                            <div class="responsive" id="menutoggle" @click="menutoggle"><i class="fas fa-solid fa-bars"></i></div>
                            <div class="logo">
                                <router-link :to="{ name: 'HomePage'}"><img :src="companyLogo" alt="logo"></router-link>
                            </div>
                            <button class="btn toggle-button-right" @click="OpenRightMenu">
                                <i class="houzez-icon icon-single-neutral-circle ml-1"></i>
                            </button><!-- toggle-button-right -->
                        </div>
                        <div class="col-xl-10 col-lg-9 col-md-3" v-if="!isUserLoggedIn">

                            <button class="btn toggle-button-right" @click="OpenRightMenu">
                                <i class="houzez-icon icon-single-neutral-circle ml-1"></i>
                            </button><!-- toggle-button-right -->


                            <div id="menu" class="main-menu text-right" :class="isTextRed && 'active-nav'">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li v-for="link in NavLinks">
                                            <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                        </li>
                                        <li class="login-link"><a href="#" data-toggle="modal" @click="this.$emit('openLoginModel')" data-target="#login-register-form" class="top-btn"><i class="fas fa-user"></i> Login</a></li>
                                        <li><a href="#" data-toggle="modal" @click="$emit('openSignUpdmodel')" data-target="#login-register-form" class="top-btn"><i class="fas fa-user-circle"></i> Signup</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div class="col-xl-10 col-lg-9 col-md-2" v-if="isUserLoggedIn">


                            <div class="main-nav navbar slideout-menu slideout-menu-right open " id="nav-mobile1" v-if="IsOpenRightMenu">

                                <ul id="mobile-main-nav" class="navbar-nav mobile-navbar-nav" v-if="isUserLoggedIn">

                                    <side class="mobileMenu"></side>
                                </ul>

                            </div>
                            <div id="menu" class="main-menu text-right" :class="isTextRed && 'active-nav'">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li v-for="(link, index) in NavLinks" class="dropdown" :key="index">
                                            <router-link :class="[JSON.parse(link.childMenu).length >0 ? 'dropdown-toggle' : '','nav-link']" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>

                                            <ul class="dropdown-menu" v-if="JSON.parse(link.childMenu).length > 0">
                                                <li v-for="childLink in JSON.parse(link.childMenu)">
                                                    <!--<a class="dropdown-item" :href="childLink.displayText">{childLink.displayText}}</a>-->
                                                    <router-link class="nav-link" :to="{path:childLink.routeUrl}">{{childLink.displayText}}</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown pr-link show">
                                            <router class="nav-link dropdown-toggle" data-toggle="dropdown" href="#/dashboard" aria-expanded="true">My Account</router>
                                            <ul class="dropdown-menu show">
                                                <li>
                                                    <router-link class="login-link" routerlinkactive="active" to="dashboard" ng-reflect-router-link-active="active" href="#/dashboard">Dashboard</router-link>
                                                </li>
                                                <li>
                                                    <a class="login-link" routerlinkactive="active" @click="this.$emit('logout')" ng-reflect-router-link-active="active">Logout</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </header>

</template>

<script>
    import side from '@/views/AuthorizedUserView/AuthorizeUserLayout/SideMenu.vue'
    import { ref } from 'vue'

   
    
    export default {
        components: {
            side
        },
        name: 'HeaderVue',
        props: ['NavLinks','isUserLoggedIn'],
        emits: ["openSignUpdmodel", "openLoginModel"],
        
        data() {
            const isTextRed = ref(false)
           function menutoggle() {
                isTextRed.value = !isTextRed.value
            }
           
            return {
                companyLogo: '',
                isscrolled: false,
                IsOpenRightMenu: false,
                isTextRed,
                menutoggle,
            }
        },
        created() {
            this.getCompanyLogo();
        },

        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },
        methods: {
           
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu1");
                    }
                    // footerbottom.classList.add("show-menu1");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu1");
                    }
                    //  footerbottom.classList.remove("show-menu1");
                }

            }, ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let navbar = document.getElementsByClassName("navbar-default");
                    let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                    let sectionFooter = document.getElementsByClassName("section-footer");

                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    this.IsOpenLeftMenu = false

                }

            },
            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }

            },
            }
        }
</script>