import { createToaster } from "@meforma/vue-toaster";
import filters from './filters.js'

function enbalePopUpCss() {
    var id = document.getElementById('isvisbleModalFade');
    id.classList.add('show')
    document.body.style.overflow = 'hidden ';
    document.body.style.height = '100vh';
}
function yearclick(chk) {

    if (chk == '1') {

        const box1 = document.getElementsByClassName('yearlyprice1')[0];
        box1.style.visibility = 'hidden';

        const box2 = document.getElementsByClassName('yearlyprice3')[0];
        box2.style.visibility = 'hidden';

        const box3 = document.getElementsByClassName('yearlyprice2')[0];
        box3.style.visibility = 'visible';

        const box4 = document.getElementsByClassName('yearlyprice4')[0];
        box4.style.visibility = 'visible';
    }
    else {

        const box1 = document.getElementsByClassName('yearlyprice1')[0];
        box1.style.visibility = 'visible';

        const box2 = document.getElementsByClassName('yearlyprice3')[0];
        box2.style.visibility = 'visible';

        const box3 = document.getElementsByClassName('yearlyprice2')[0];
        box3.style.visibility = 'hidden';

        const box4 = document.getElementsByClassName('yearlyprice4')[0];
        box4.style.visibility = 'hidden';
    }
}
function disablePopUpCss() {
    document.body.style.overflow = 'visible';
    document.body.style.removeProperty('height');
    var id = document.getElementById('isvisbleModalFade');
    id.classList.remove('show')
}

function enbaleComparePopUpCss() {
    if (!document.body.classList.contains('comparesion-page')) {
        document.body.classList.add('comparesion-page')
    }
    let comparepopElement = document.getElementById('comparisonblock');
    if (comparepopElement != null && !comparepopElement.classList.contains('show')) {
        comparepopElement.classList.add('open');
    }
}

function setFile(file, type = 'img', requiredSize = '1KB') {
    let isFileTypeValid = false;
    if (type == 'Excel') {
        isFileTypeValid = ValidateExcelFile(file);
    } else {
         isFileTypeValid = ValidateImageFile(file);
    }
    if (!isFileTypeValid) {
        file.value = '';
        return false;
    }
    let isFileValid = CheckDocumentSize(type, file.files[0].size, requiredSize)
    if (!isFileValid) { file.value = ''; }
}


function ValidateImageFile(file) {

    const fileName = file.files[0].name;
    const extensions = [".png", ".jpeg", ".jpg", ".gif"];
    let IsExtesnsionValid = false;
    for (var j = 0; j < extensions.length; j++) {
        var sCurExtension = extensions[j];
        if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { IsExtesnsionValid = true; break; }
    }
    if (!IsExtesnsionValid) {
        const toaster = createToaster({ position: 'top-right' });
        toaster.error(`Valid file format is ${extensions.join(", ")}`);

        return false;
    }

    return true;
}
function ValidateExcelFile(file) {

    const fileName = file.files[0].name;
    const extensions = [".xls", ".xlsx"];
    let IsExtesnsionValid = false;
    for (var j = 0; j < extensions.length; j++) {
        var sCurExtension = extensions[j];
        if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { IsExtesnsionValid = true; break; }
    }
    if (!IsExtesnsionValid) {
        const toaster = createToaster({ position: 'top-right' });
        toaster.error(`Valid file format is ${extensions.join(", ")}`);

        return false;
    }

    return true;
}


function CheckDocumentSize(type, filesize, filelength) {
    if (filters.IsNull(type.toLowerCase(), true, null) == null) {
        return false;
    }
    if (filelength == "30MB") {

        if (filesize >= 31457280) {    // 30MB Size in Bytes.
            const toaster = createToaster({ position: 'top-right' });
            toaster.error("Please upload a file of max. size 30MB.");
            return false;
        }

        return true;
    }
    if (filelength == "10MB") {
        if (filesize >= 10485760) { //10MB Size in Bytes.
            const toaster = createToaster({ position: 'top-right' });
            toaster.error("Please upload a file of max. size 10MB.");

            return false;
        }
        return true;
    }
    if (filelength == "1KB") {

        if (filesize >= 1000000) { //10MB Size in Bytes.
            const toaster = createToaster({ position: 'top-right' });
            toaster.error("Please upload a file of max. size 1KB.");
            return false;
        }
        return true;
    }


    if (filelength == "5MB") {
        if (filesize >= 5242880) {//5MB Size in Bytes.1, 000, 000
            const toaster = createToaster({ position: 'top-right' });

            toaster.error("Please upload a file of max. size 5MB.");
            return false;
        }
        return true;
    }

    if (filelength == "max") {
        return true;
    }

    return false;

}
function objectToGetParams(object) {
    return (
        '?' +
        Object.keys(object)
            .filter(key => !!object[key])
            .map(key => `${key}=${encodeURIComponent(object[key])}`)
            .join('&')
    );
}

function convertDecimalValueInFormat(value) {
    try {
        value = parseFloat(value, 10);
        value = value.toFixed(2);
        value = parseFloat(value, 10);
        value = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        return value;
    } catch (ex) {
        return value
    }
}

function EncryptUserName(txtUserName, vm) {
    debugger
    var key = CryptoJS.enc.Utf8.parse(vm.$custConfig.encryptkey);
    var iv = CryptoJS.enc.Utf8.parse(vm.$custConfig.encryptiv);

    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(txtUserName), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
    var encoded = CryptoJS.enc.Base64.parse(encryptedlogin).toString(CryptoJS.enc.Hex);
    return encoded
}

export default {
    enbalePopUpCss,
    disablePopUpCss,
    setFile,
    objectToGetParams,
    enbaleComparePopUpCss,
    convertDecimalValueInFormat,
    EncryptUserName,
    yearclick
}