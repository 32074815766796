<template>
    <loading v-model:active="isLoading">
        <!--<div id="preloader"></div>-->
    </loading>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    export default {
        props: {
            isLoading: Boolean,
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
            }
        }
    }
</script>
<style>
    
</style>