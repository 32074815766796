<template>
    <footer class="ltn__footer-area  ">
        <div class="footer-top-area  section-bg-2 plr--5">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-about-widget">
                            <div class="footer-logo">
                                <div class="site-logo site-logo-footer">
                                    <router-link :to="{ path: 'Home'}">  <img :src="companyLogo" alt="Logo"></router-link>
                                </div>
                            </div>
                            <p>{{FooterLogoDescription}} </p>
                            <div class="footer-address">
                                <ul>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-placeholder"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p>{{OurAddress}}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-call"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p><a :href="'tel:'+ OurPhone">{{OurPhone}}</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="footer-address-icon">
                                            <i class="icon-mail"></i>
                                        </div>
                                        <div class="footer-address-info">
                                            <p><a href="mailto:info@zorbis.com">{{OurEmail}}</a></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!--<div class="ltn__social-media mt-20">
                                <SocialMediaIconComponent></SocialMediaIconComponent>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-menu-widget clearfix">
                            <h4 class="footer-title">Links</h4>
                            <div class="footer-menu">
                                <ul>
                                    <li v-for="link in footerNavLinks"><router-link :to="{ name: link.routeUrl}">{{link.displayText}}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-6 col-sm-6 col-12">
                        <div class="footer-widget footer-menu-widget clearfix">
                            <h4 class="footer-title">Services</h4>
                            <div class="footer-menu">
                                <ul>

                                    <li v-for="serviceLink in serviceLinks"> <router-link :to="{ name: serviceLink.routeUrl}">{{serviceLink.displayText}}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!--  newsletter Section -->
                    <newsletter></newsletter>
                    <!--  End newsletter Section -->


                </div>
            </div>
        </div>
        <div class="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div class="container-fluid ltn__border-top-2">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="ltn__copyright-design clearfix">
                            <p v-html="Copyrights"></p>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 align-self-center">
                        <div class="ltn__copyright-menu text-end">
                            <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Claim</a></li>
                                <li><a href="#">Privacy & Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- FOOTER AREA END -->
</template>

<script>
    import newsletter from '@/views/NewsLetter/newsletter.vue'
    import SocialMediaIconComponent from '@/views/SocialMediaIcons/socialMediaIcons.vue'
    export default {
        name: 'FooterVue',
        props: ["footerNavLinks", "serviceLinks", "FooterLogoDescription", "OurAddress", "OurEmail", "OurPhone", "Copyrights"],
        components: {
            newsletter,
            SocialMediaIconComponent
        },
        data() {
            let currentYear = new Date().getFullYear();

            return {
                currentYear,
                companyLogo: '',
                companyName: ''
            }
        },
        created() {
            this.getCompanyLogo();
            this.companyName = localStorage.getItem('companyName')
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companyLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../../../../assets/images/logo/default-logo.png') : logoUrl;
            }
        }

    }
</script>