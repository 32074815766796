<template>

    <div id="confirmation" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-confirm modal-dialog-centered">
            <div class="modal-content pt-3">
          
                <div class="modal-body text-center">
                    <div class="icon-box close" data-bs-dismiss="modal" aria-hidden="true" @click="$emit('cancel')" style="position: absolute; right: 20px; top: 0; cursor: pointer;">
                        <i class="fas fa-times"></i>
                    </div>
                    <div class="icon-exp"><i class="fa-solid fa-exclamation"></i></div>
                    <h4 class="modal-title w-100 mb-2 mt-2">{{header}}</h4>
                    <p class="mb-0">{{ question }}</p>
                </div>
                <div class="modal-footer align-items-center justify-content-center border-0 pt-0 pb-4">
                    <button type="button" class="btn btn-primary" @click="$emit('confirm')">{{confirm}}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('cancel')">{{cancel}}</button>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>


</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        props: ["question", "header", "cancel", "confirm"],
        emits: ["confirm", "cancel"]
    });

</script>

<style>

    .cmnmodel .modal-dialog {
        max-width: 450px;
    }

    .cmnmodel .btn-secondary {
        border-radius: 5px;
        padding:;
        line-height: 12px;
        min-height: auto;
        height: 43px;
    }

    .cmnmodel .btn {
        padding: 9px 34px;
        font-size: 16px;
    }

    .icon-exp {
        border: 2px solid #ffb300;
        color: #ffb300;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        border-radius: 50%;
        font-size: 32px;
        line-height: 56px;
        margin-bottom: 0px;
    }

    .modal-title {
        font-size: 27px;
    }
    #confirmation .btn {
        min-width: 99px;
    }
</style>