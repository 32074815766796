

import axiosInstance from "./apiSetting";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            if (config["Content-Type"] == "multipart/form-data") {
                config.headers["Content-Type"] = "multipart/form-data";
            } else {
                config.headers["Content-Type"] = "application/json";
            }
            config.headers['Access-Control-Allow-Origin'] = '*'
            config.headers['Access-Control-Allow-Methods'] = 'GET,POST, PATCH,PUT,DELETE,OPTIONS';
            config.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token Origin,X-Requested-With,Accept';
            config.headers['Cache-Control'] = 'no-cache';
            config.headers['Pragma'] = 'no-cache';
            config.headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
            config.headers['If-Modified-Since'] = '0';
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");


            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            return Promise.reject(err);
        }
    );
};

export default setup;