<template>
   

           
    <Form class="newsltter-form" autocomplete="off" ref="newsLetterdata" id="EmailForm" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}">
        <div class="newsletter_content_box">
            <Field type="text" id="myForm" name="emailname" v-model="emailValuecomp"
                   :class="{ 'form-control' : true ,'is-invalid': errors.emailname }"
                   placeholder="Enter Email" />
            <button type="submit" class="icon wooc-btn-search">Submit</button>
            <div class="invalid-feedback">{{errors.emailname}}</div>
        </div>

        
    </Form>
            



</template>
<script>
    import { Form, Field } from 'vee-validate';
    export default {
        components: {
            Form, Field
        },
        props: ['schema', 'ref'],
        emits: ["submitNewsLetter"],
        data() {
            return {
                emailValuecomp:''
                }
        },
        created(){

              
           // this.resetsub();
        },
        methods: {
            onSubmit() {
                this.$emit("submitNewsLetter", this.emailValuecomp);
                this.$refs.newsLetterdata.resetForm();
            },
        }
    }
</script>

<style>
    @media (max-width:768px){
        .invalid-feedback{
            text-align:left;
            margin-top:-5px;
        }
    }
</style>