<template>
    <div class="modal fade login-register-form show" id="login-register-form" tabindex="-1" aria-labelledby="login-register-form" style="display: block; padding-left: 0px;" aria-modal="true" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" @click="$emit('cancel')"><span><i class="fa fa-times"></i></span></button>
                <div class="modal-body">
                    <div class="tab-content">
                        <div class="tab-pane fade login-form-tab" id="login-form-tab" role="tabpanel">
                            <div id="hz-login-messages" class="hz-social-messages"></div>
                        </div><!-- login-form-tab -->
                        <div class="tab-pane fade register-form-tab active show" id="register-form-tab" role="tabpanel" aria-labelledby="register-form-tab">
                            <Form autocomplete="off" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                                <div class="popup-heading">Create an account</div>
                                <div class="register-form-wrap">

                                    <div class="form-group">
                                        <div class="form-group-field username-field">
                                            <Field type="text" name="firstname" v-model="signUpData.firstname" :class="{ 'form-control mb-1' : true ,'is-invalid': errors.firstname }"
                                                   maxlength="200" placeholder="Enter First Name*" />
                                            <div class="invalid-feedback">{{errors.firstname}}</div>
                                        </div><!-- input-group -->
                                    </div><!-- form-group -->

                                    <div class="form-group">
                                        <div class="form-group-field username-field">
                                            <Field type="text" name="lastname" v-model="signUpData.lastname" :class="{ 'form-control mb-1' : true ,'is-invalid': errors.lastname }"
                                                   maxlength="200" placeholder="Enter Last Name*" />
                                            <div class="invalid-feedback">{{errors.lastname}}</div>
                                        </div><!-- input-group -->
                                    </div><!-- form-group -->

                                    <div class="form-group">
                                        <div class="form-group-field username-field">
                                            <Field type="text" name="username"
                                                   v-model="signUpData.username" :disabled="signUpData.id!=null"
                                                   maxlength="200"
                                                   placeholder="Enter Username*"
                                                   :class="{ 'form-control mb-1 no-transform' : true ,'is-invalid': errors.username }" />
                                            <div class="invalid-feedback">{{errors.username}}</div>
                                        </div><!-- input-group -->
                                    </div><!-- form-group -->


                                    <div class="form-group">
                                        <div class="form-group-field email-field">
                                            <Field type="text" name="email" v-model="signUpData.email" :class="{ 'form-control mb-1' : true ,'is-invalid': errors.email }"
                                                   maxlength="200" placeholder="Enter Email*" />
                                            <div class="invalid-feedback">{{errors.email}}</div>
                                        </div><!-- input-group -->
                                    </div><!-- form-group -->

                                    <div class="form-group">
                                        <div class="form-group-field phone-field">
                                            <Field v-slot="{field }"
                                                   v-model="signUpData.phone"
                                                   name="phone"
                                                   value="value">

                                                <input-mask name="phone"
                                                            v-model="signUpData.phone"
                                                            :model-value="field.value"
                                                            mask="(999) 999-9999"
                                                            placeholder="Enter Phone*"
                                                            :class="{'form-control mb-1' : true , 'is-invalid': errors.phone} "
                                                            type="text"></input-mask>
                                                <div class="invalid-feedback">{{errors.phone}}</div>
                                            </Field>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="form-group-field password-field">
                                            <Field v-bind:type="isShowPassword ? 'text':'password'" placeholder="Enter Password*" name="password" v-model="signUpData.password"
                                                   data-gtm-form-interact-field-id="1" maxlength="200"
                                                   class="form-control mb-1" autocomplete="new-password"
                                                   :class="{ 'is-invalid': errors.password }" />
                                            <span toggle="#password-field" :class="togglePasswordIcon" @click="showPassword"></span>
                                            <div class="invalid-feedback">{{errors.password}}</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group-field password-field">
                                            <Field v-bind:type="isShowConfirmPassword ? 'text':'password'" name="confirmPassword" v-model="signUpData.confirmPassword"
                                                   :class="{ 'form-control mb-1' : true ,'is-invalid': errors.confirmPassword }"
                                                   maxlength="200" placeholder="Enter Confirm Password*" />
                                            <span toggle="#password-field" :class="toggleConfirmPasswordIcon" @click="showConfirmPassword"></span>
                                            <div class="invalid-feedback">{{errors.confirmPassword}}</div>
                                        </div><!-- input-group -->
                                    </div><!-- form-group -->

                                </div><!-- login-form-wrap -->
                                <div class="form-group mt-10">
                                    <Field v-slot="{field }"
                                           v-model="signUpData.accountType"
                                           name="accountType" value="value">
                                        <dynamic-select :options="listAccountType"
                                                        @search="onSearchEventHandler"
                                                        placeholder="Select Account Type*"
                                                        optionValue="masterId"
                                                        optionText="masterValue"
                                                        :class="{ 'form-control mb-1' : true ,'is-invalid': errors.accountType }"
                                                        v-model="signUpData.accountType"
                                                        :model-value="field.value"
                                                        @input="field.onInput.forEach((fn) => fn($event.value))" />
                                        <div class="invalid-feedback">{{errors.accountType}}</div>
                                    </Field>
                                </div>


                                <!--<div class="form-tools">
                                    <input name="isTermAndCondition" type="checkbox" v-model="signUpData.isTermAndCondition" id="isTermAndCondition" :value="true" class="form-check-input" />
                                    <label class="form-check-label" for="isTermAndCondition">
                                        &nbsp; I agree with your <a target="_blank" href="#">Terms &amp; Conditions</a>
                                    </label>
                                </div>-->

                                <div class="form-tools">
                                    <label class="control control--checkbox">
                                        <input name="isTermAndCondition" type="checkbox" v-model="signUpData.isTermAndCondition" id="isTermAndCondition" :value="true">
                                        I agree with your <a target="_blank" href="terms-and-services.html">Terms &amp; Conditions</a>        <span class="control__indicator"></span>


                                    </label>
                                </div>

                                <button type="submit" class="btn btn-primary btn-full-width">
                                    Register
                                </button>
                                <div class="login_Register_option">
                                    <a @click="switchToSignin" class="modal-toggle-1 nav-link active">
                                        Already a member? Sign in!
                                    </a>
                                </div>
                            </Form>
                        </div><!-- register-form-tab -->

                    </div><!-- tab-content -->

                </div><!-- modal-body -->
                <div class="modal_login_image_wrapper">
                    <div class="modal_login_image"></div>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
</template>
<script>
    import { Form, Field } from 'vee-validate';
    import DynamicSelect from '@/views/shared/singleSelect/singleDrp.vue';
    import InputMask from '@/views/shared/inputmask/inputmasktemplate.vue'

    export default {
        props: ["companyName", "schema", "signUpData", "isopenSignUpModal", "listAccountType"],
        emits: ["submitClicked", "cancel","switchToSignin"],
        components: {
            Form, Field, InputMask,
            "dynamic-select": DynamicSelect,
        },

        data() {
            return {
                isShowPassword: false,
                togglePasswordIcon: 'far fa-eye-slash toggle-password',
                isShowConfirmPassword: false,
                toggleConfirmPasswordIcon: 'far fa-eye-slash toggle-password',
            }
        },
        methods: {
            onSubmit() {
                this.$emit("submitClicked", this.signUpData);
            },
            showPassword() {
                if (this.togglePasswordIcon == 'far fa-eye-slash toggle-password') {
                    this.togglePasswordIcon = 'far fa-eye toggle-password';
                    this.isShowPassword = true;
                } else {
                    this.togglePasswordIcon = 'far fa-eye-slash toggle-password'
                    this.isShowPassword = false;
                }

            },
            showConfirmPassword() {
                if (this.toggleConfirmPasswordIcon == 'far fa-eye-slash toggle-password') {
                    this.toggleConfirmPasswordIcon = 'far fa-eye toggle-password';
                    this.isShowConfirmPassword = true;
                } else {
                    this.toggleConfirmPasswordIcon = 'far fa-eye-slash toggle-password'
                    this.isShowConfirmPassword = false;
                }
            },
            switchToSignin(){
                 this.$emit("switchToSignin");
            }
        }
    }
</script>